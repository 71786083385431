import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatIcon from "@mui/icons-material/Chat";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Snackbar,
  Typography,
} from "@mui/material";
import { onChildAdded, onChildChanged, onValue, ref } from "firebase/database";
import { db, signOutUser } from "../../../firebase.config";
import { timeAgo } from "../../../shared/utils/timeAgo";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { logoutUser } from "../../../redux/userSlice";
import { Grid } from "react-loader-spinner";
import EachNotification from "./EachNotification";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { gapi } from "gapi-script";
// import { google } from "googleapis";

const AuthenticatedUserNav = () => {
  const [notifications, setNotifications] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [newUpdatedNot, setNewUpdatedNot] = useState([]);
  const user = useSelector((state) => state.user);
  const profile = user?.profile;
  const navigate = useNavigate();
  const messageButtonRef = useRef(null);
  const dispatch = useDispatch();

  const getNotification = () => {
    setOpenNotification(true);
  };

  const showMessages = () => {
    setOpenMessage((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
    const notificationRef = ref(db, `notification/users/${user.profile.uid}`);
    return onValue(notificationRef, (snap) => {
      if (!snap.val()) return;
      const unsortedNotifications = Object.values(snap.val());

      const sortedNotifications = unsortedNotifications.sort(
        (a, b) => b.createAt - a.createAt
      );
      setNotifications(sortedNotifications);
    });
  }, [user.profile.uid]);

  useEffect(() => {
    const notificationRef = ref(db, `/notification/users/${user.profile.uid}`);
    return onChildAdded(notificationRef, (snap) => {
      const newData = snap.val();
      const key = snap.key;
      if (!snap.val().seen) {
        setNewUpdatedNot((prevData) => [...prevData, { key, ...newData }]);
        setTimeout(() => {
          setNewUpdatedNot((prevData) =>
            prevData.filter((item) => item.key !== key)
          );
        }, 10000); // 10000 milliseconds = 10 seconds
      }
    });
  }, []);

  const logout = async () => {
    try {
      if (window.confirm("are you sure you want to sign out")) {
        await signOutUser();
        dispatch(logoutUser());
        toast.success("you sign out from your account");
        navigate("/");
      }
    } catch (error) {
      toast.error(error + "something is wrong");
    }
  };
  const handleClose = () => {
    setNewUpdatedNot([]);
  };

  // const clientId = "YOUR_CLIENT_ID";
  // const scope = "https://www.googleapis.com/auth/chat.bot";

  // useEffect(() => {
  //   function initClient() {
  //     gapi.load("client:auth2", () => {
  //       gapi.client
  //         .init({
  //           clientId: clientId,
  //           scope: scope,
  //         })
  //         .then(() => {
  //           gapi.auth2
  //             .getAuthInstance()
  //             .signIn()
  //             .then(() => {
  //               const accessToken = gapi.auth2
  //                 .getAuthInstance()
  //                 .currentUser.get()
  //                 .getAuthResponse().access_token;
  //               getData(accessToken);
  //             });
  //         });
  //     });
  //   }

  //   initClient();
  // }, []);

  // const getData = async () => {
  // const auth = getAuth();
  // const user = auth.currentUser;
  // if (user) {
  //   const idToken = await user.getIdToken(true);
  //   // Exchange Firebase ID token for OAuth 2.0 access token
  //   const { OAuth2 } = google.auth;
  //   const oauth2Client = new OAuth2(
  //     'YOUR_CLIENT_ID',
  //     'YOUR_CLIENT_SECRET',
  //     'YOUR_REDIRECT_URL'
  //   );
  //   oauth2Client.setCredentials({
  //     id_token: idToken,
  //   });
  //   const tokens = await oauth2Client.getAccessToken();
  //   const accessToken = tokens.token;
  //   // Make API request to Google Chat API
  //   const response = await fetch("https://chat.googleapis.com/v1/spaces", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + accessToken,
  //     },
  //   });
  //   const data = await response.json();
  //   console.log(data);
  // } else {
  //   console.log('No user is signed in.');
  // }
  // const data = await response.json();
  // const accessToken = data;
  // console.log(accessToken);
  // // Make API request to retrieve chat spaces
  // const chatSpacesResponse = await fetch(
  //   "https://chat.googleapis.com/v1/spaces",
  //   {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   }
  // );
  // const chatSpacesData = await chatSpacesResponse.json();
  // console.log("Chat spaces:", chatSpacesData);
  // try {
  //   const idToken = await user.currentUser.getIdToken(true);
  //   const apiKey = "AIzaSyD1iYeXhK-wku_v-qV-FGMCoy2IyYySyM8";
  //   const response = await fetch(
  //     `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         grant_type: "refresh_token",
  //         refresh_token: idToken,
  //       }),
  //     }
  //   );
  //   const data = await response.json();
  //   const accessToken = data.access_token;
  //   console.log("Access Token:", accessToken); // Log the access token
  //   // const auth = await getAuth();
  //   const url = `https://chat.googleapis.com/v1/spaces?key=${apiKey}`;
  //   fetch(url, {
  //     headers: { authorization: `Bearer ${idToken}` },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // } catch (error) {
  //   console.log(error);
  // }
  // };
  const getData = () => {
    fetch("https://chat.googleapis.com/v1/spaces", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  };
  return (
    <div className="w-full h-12 bg-cu-blue relative flex items-center">
      {/* <div className="h-full absolute top-[2px]"></div> */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 text-center"
        style={{ lineHeight: "3px" }}
        // onClick={getData}
      >
        <h2 className="w-full text-white font-bold text-2xl" onClick={getData}>
          LMS
        </h2>
        <p style={{ color: "white", margin: "auto", fontSize: "12px" }}>
          (learning management system)
        </p>
      </div>
      {newUpdatedNot.length ? (
        <Box sx={{ width: 500 }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            onClose={handleClose}
            message={
              <div className="flex flex-col gap-y-2">
                {newUpdatedNot.map((note) => {
                  return (
                    <div className="border-b-2 border-b-cu-red">
                      <p className="font-bold text-lg ">{note.title}</p>
                      <p className="ps-3 w-96">{note.text}</p>
                    </div>
                  );
                })}
              </div>
            }
            key={"topright"}
          />
        </Box>
      ) : (
        ""
      )}
      <div className="ml-auto flex items-center gap-x-3 pe-2 me-4">
        <Typography className="text-cu-red">
          Ver: {process.env.REACT_APP_VERSION}
        </Typography>
        <IconButton
          aria-label="messages"
          onClick={showMessages}
          ref={messageButtonRef}
        >
          <Badge badgeContent={0} color="primary" showZero>
            <ChatIcon className="text-white" sx={{ fontSize: "25px" }} />
          </Badge>
        </IconButton>
        <div>
          <Popper open={openMessage} anchorEl={messageButtonRef.current}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper className="p-2 mt-3 ps-4 pe-4 underline">
                <a
                  target="_blank"
                  href="https://mail.google.com/mail/u/0/#chat/home"
                  className="text-blue-500"
                >
                  Open gmail
                </a>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
        <IconButton aria-label="notification" onClick={getNotification}>
          <Badge
            badgeContent={
              notifications.filter((notification) => !notification.seen).length
            }
            color="primary"
            overlap="circular"
          >
            <NotificationsNoneIcon
              className="text-white"
              sx={{ fontSize: "30px" }}
            />
          </Badge>
        </IconButton>
        {openNotification && (
          <ClickAwayListener
            onClickAway={() => {
              setOpenNotification(false);
            }}
          >
            <div className="absolute top-12 right-2 w-96 h-96 overflow-auto z-20">
              {notifications?.map((notification) => {
                return <EachNotification notification={notification} />;
              })}

              {/* <Collapse in={false}> */}
              {/* </Collapse> */}
              {/* <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              This is an info notification with an informative title.
            </Alert>
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              This is a warning notification with a cautious title.
            </Alert>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              This is an error notification with a scary title.
            </Alert> */}
            </div>
          </ClickAwayListener>
        )}
        <IconButton aria-label="avatar">
          {/* <Badge
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "transparent",
                  color: "white",
                },
              }}
              badgeContent={<ExpandCircleDownOutlinedIcon />}
              color="primary"
              className="w-12 h-12 object-contain"
              // overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            > */}
          <div className="myc-profileBox text-left flex flex-col items-center relative">
            <IconButton aria-label="avatar">
              <Avatar src={profile?.photoUrl.url} />
              {/* </Badge> */}
            </IconButton>
            <div className="mt-10 flex flex-col absolute p-5 hidden absolute right-0 w-96 h-56 z-20">
              <div className="bg-white flex flex-col gap-y-2 border p-4 rounded-xl w-full">
                <div className="text-blue-500 text-start">
                  <Link
                    to={
                      profile.role === "student"
                        ? "/studentpanel/profile"
                        : profile.role === "admin"
                        ? "/adminpanel"
                        : profile.role === "instructor"
                        ? "/adminpanel"
                        : profile.role === "staff"
                        ? "/staffpanel/profile"
                        : "/notfound"
                    }
                  >
                    Profile
                  </Link>
                </div>
                <div>
                  {profile.name} {profile.family}
                </div>
                <div className="text-md">{profile.email}</div>
                <button className="text-red-500" onClick={logout}>
                  Log Out
                </button>
              </div>
            </div>
          </div>
          {/* </Badge> */}
        </IconButton>
        {/* <img
          // src={}
          src={user.profile.photoUrl}
          alt=""
          className="w-12 h-12 border rounded-full object-contain"
        /> */}
      </div>
    </div>
  );
};

export default AuthenticatedUserNav;
{
  /* <Button */
}
// sx={{
//     width: '200px',
//     height: '50px',
//     backgroundColor: 'primary.main',
//     '&:hover': {
//         backgroundColor: 'primary.dark',
//     },
// }}
// >
// Custom Styled Button
// </Button>
