import { postImageDataToDb } from "../../../../firebase.config";
import { ReactComponent as PostLoadingComp } from "../../../../images/svg/postsvgLoading.svg";
import AddNamesInput from "../../sharedComp/AddNamesInput";
import { toast } from "react-toastify";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImageById } from "../../../../redux/allImagesSlice";
import { serverTimestamp } from "firebase/database";
import { useEffect, useState } from "react";

import EachImage from "./EachImage";
import { v4 as uuidv4 } from "uuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GroupCodeSection from "../../sharedComp/GroupCodeSection";
import LoadingButton from "../../../../shared/comp/LoadingButton";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddNewImages = () => {
  const { imageId: initialImageId } = useParams();
  const [imagesId, setImagesId] = useState(initialImageId);
  const image = useSelector((state) => selectImageById(state, imagesId));
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  // const [list, setList] = useState([]);
  const [name, setName] = useState(image?.info?.name || "");
  const [category, setCategory] = useState(image?.info?.category || "");
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [explain, setExplain] = useState(image?.info?.explain);
  const [authors, setAuthors] = useState(image?.info?.authors || []);
  const [labels, setLabels] = useState(image?.info?.labels || []);
  //   const [imagePath, setImagePath] = useState("");
  // const [imageFile, setImageFile] = useState(
  //   (image?.files && Object.values(image?.files)) || ""
  // );
  const [groupCode, setGroupCode] = useState(
    image?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : "")
  );
  useEffect(() => {
    if (imagesId) return;
    setImagesId(uuidv4());
  }, [imagesId]);

  // "webp", "img", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "gif", "avif"
  const storeFile = ({ replacedString, file }) => {
    const storage = getStorage();
    return new Promise((resolve, reject) => {
      const storageRef = ref(
        storage,
        `resources/images/${imagesId}/${replacedString}`
      );
      console.log("start save");
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve({
              url: downloadURL,
              path: `resources/images/${imagesId}/${replacedString}`,
              name: replacedString,
            });
          });
        }
      );
    });
  };

  const saveUploadedFile = async (list) => {
    const prevFile = image?.files || {};
    const imagesFile = list.reduce((acc, curr) => {
      acc[curr.name] = curr;
      return acc;
    }, {});

    const imageToUpload = {
      info: {
        imageId: imagesId,
        name: image?.info.name || "",
        category: image?.info.category || "",
        explain: image?.info.explain || "",
        authors: image?.info.authors || "",
        labels: image?.info.labels || "",
        createAt: serverTimestamp(),
        creatorId: user.profile.uid,
        groupCode,
      },
      files: { ...prevFile, ...imagesFile },
      id: imagesId,
    };
    try {
      const response = await postImageDataToDb(imageToUpload);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const onInputFile = async (e) => {
    if (!e.target.files.length) return;

    if (!groupCode) {
      toast.error("please specify group code and try again");
      return;
    }
    const promises = [...e.target.files].map((file) => {
      const replacedString = file.name.replace(/[#$\/\[\].\s]/g, "_");
      if (image?.files && Object.keys(image?.files).includes(replacedString)) {
        if (
          window.confirm(
            `file with name "${replacedString}" already exists are you want to overwrite it ?`
          )
        ) {
          return storeFile({ replacedString, file });
        } else return null;
      } else {
        return storeFile({ replacedString, file });
      }
    });
    try {
      setLoading(true);
      const results = await Promise.all(promises);
      const objImage = results.filter((a) => a !== null);
      if (objImage.length) {
        await saveUploadedFile(objImage);
        toast.success("new files added successfully");
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }

    // setSelectedFiles((prev) => {
    //   const files = [...prev, ...Object.values(e.target.files)];
    //   const sortFiles = files.sort((a, b) => a.name <= b.name);
    //   return sortFiles;
    // });
  };

  // useEffect(() => {
  //   if (!name || !category) return;
  //   // setImagePath(`/library/images/${category}/${name}`)
  //   setPdfPath(`/pdf/`);
  // }, [name, category]);

  const submitData = async (e) => {
    e.preventDefault();

    // console.log(imageFile);
    const imageToUpload = {
      info: {
        imageId: imagesId,
        name,
        category,
        explain,
        authors,
        labels,
        createAt: serverTimestamp(),
        creatorId: user.profile.uid,
        groupCode,
      },
      files: image.files,
      id: imagesId,
    };
    try {
      setPostLoading(true);
      const response = await postImageDataToDb(imageToUpload);
      toast(response.message);
      setPostLoading((prev) => !prev);
      navigate(`/${user.profile.role}panel/resources/image`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    // <Container maxWidth="md">
    <form onSubmit={submitData}>
      <div style={{ padding: "10px", marginTop: "20px" }}>
        <Box className="flex items-center gap-x-3 mb-5">
          <Typography>IMAGE ID : {imagesId}</Typography>
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            size="small"
            sx={{}}
          >
            <CloudUploadIcon
              sx={{ fontSize: "40px" }}
              className="text-cu-blue"
            />
            <VisuallyHiddenInput
              type="file"
              onChange={onInputFile}
              accept={"image/*"}
              multiple={true}
            />
          </Button>
          <div className="w-80 ml-auto">
            {user.profile.role === "admin" && (
              <GroupCodeSection
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                disabled={image?.info?.groupCode}
              />
            )}
          </div>
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Name"
                variant="outlined"
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Category"
                variant="outlined"
                fullWidth
                // required
              />
            </Grid>
          </Grid>
          {/* <div className="w-2/3 m-auto">
            <div className="flex flex-col items-center p-3"> */}
          <div className="flex p-5 gap-2 flex-wrap">
            {image?.files &&
              Object.values(image.files).map((file) => (
                <EachImage file={file} imagesId={imagesId} key={file.name} />
              ))}
          </div>
          {/* </div>
          </div> */}
        </Box>

        <Box mt={5} display="flex" flexDirection="column" gap={2}>
          <AddNamesInput
            placeholder="Authors"
            parentList={authors}
            setParentList={setAuthors}
          />
          <AddNamesInput
            placeholder="Labels"
            parentList={labels}
            setParentList={setLabels}
          />
        </Box>

        <TextField
          value={explain}
          onChange={(e) => setExplain(e.target.value)}
          label="Explanation"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />
        {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="m-auto"
          disabled={!imagesId || !image || loading || !groupCode}
        >
          {postLoading && <PostLoadingComp />}
          Submit
        </Button> */}
        <div className="w-96 m-auto">
          <LoadingButton
            loading={postLoading}
            disabled={!imagesId || !image || loading || !groupCode}
            name={"SUBMIT"}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNewImages;
