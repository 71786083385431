import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { removeNotificationFromDb } from "../../../firebase.config";
import { useNavigate } from "react-router-dom";
import TimeShow from "../../../shared/utils/TimeShow";

const NotificationRow = ({ notification, index }) => {
  const navigate = useNavigate();

  const removeNotification = async () => {
    if (
      window.confirm(
        `are you sure to remove notification ${notification.info.title} ?`
      )
    ) {
      try {
        await removeNotificationFromDb(notification);
        toast.success("notification deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  };

  const goToEdit = () => {
    navigate(`pusheditnotification/${notification.info.notificationId}`);
  };

  return (
    <div
      className={`flex justify-between bg-slate-${
        index % 2 ? "100" : "200"
      } p-2`}
    >
      <p className="w-2/12">{notification.info.notificationId}</p>
      <p className="w-7/12" dir={notification.info.inputDirction}>
        {notification.info.title}
      </p>
      <p className="w-2/12">
        {TimeShow({ date: new Date(notification.info.createAt) })}
      </p>
      <div className="ml-auto">
        <button
          onClick={removeNotification}
          className="text-cu-red justify-end"
        >
          <DeleteForeverIcon color="bg-cu-red" />
        </button>
        <button onClick={goToEdit} className="text-cu-blue justify-end">
          <EditIcon />
        </button>
      </div>
    </div>
  );
};

export default NotificationRow;
