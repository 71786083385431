import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const EachCourseAssign = ({ course, changeList, assignedCourses }) => {
  const [assign, setAssign] = useState(false);

  useEffect(() => {
    const ifChecked = assignedCourses?.some(
      (ac) => ac === course.info.courseId
    );
    setAssign(ifChecked);
    if (ifChecked) {
      changeList({
        assign: true,
        courseId: course.info.courseId,
      });
    }
  }, []);

  const changeAssignCourse = async (e) => {
    setAssign((prev) => {
      changeList({
        assign: !prev,
        courseId: course.info.courseId,
      });
      return !prev;
    });
  };

  return (
    <p className="flex justify-between">
      <p className="me-5">{course?.info?.courseId}</p>
      <p className="text-start">{course?.info?.title}</p>
      {assign ? (
        <Button
          size="small"
          sx={{
            color: "white",
            backgroundColor: "#bf0a30",
            "&:hover": {
              backgroundColor: "#bf0a30",
            },
          }}
          onClick={changeAssignCourse}
        >
          Unassign
        </Button>
      ) : (
        <Button
          size="small"
          sx={{
            color: "white",
            backgroundColor: "#002868",
            "&:hover": {
              backgroundColor: "#002868",
            },
          }}
          onClick={changeAssignCourse}
        >
          Assign
        </Button>
      )}
      {/* <input type="checkbox" checked={assign} onChange={changeAssignCourse} /> */}
    </p>
  );
};

export default EachCourseAssign;
