import React from "react";

const TimeShow = ({ date, onlyDate }) => {
  console.log(date);
  if (!date) return;

  if (onlyDate) {
    return (
      <div>
        <p>
          {date?.getMonth()}/{date?.getDate()}/{date?.getFullYear()}
        </p>
      </div>
    );
  }

  return (
    <div>
      <p>
        {date?.getMonth()}/{date?.getDate()}/{date?.getFullYear()} at{" "}
        {String(date.getHours()).padStart(2, "0")}:
        {String(date.getMinutes()).padStart(2, "0")}
      </p>
    </div>
  );
};

export default TimeShow;
