import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { toast } from "react-toastify";
import { deleteRealItem } from "../../../firebase.config";
import PreviewIcon from "@mui/icons-material/Preview";
import { useSelector } from "react-redux";
import { getLessonsByIds } from "../../../redux/lessonsSlice";
import ActionsMenu from "../sharedComp/ActionsMenu";
const AssignmentRow = ({ item }) => {
  const user = useSelector((state) => state.user);
  const role = user.profile.role;
  const coursesInstructor =
    (user.assignedCourses && Object.keys(user.assignedCourses)) || [];
  const assignmentLessonsId =
    (item.assignedLessons && Object.keys(item.assignedLessons)) || [];
  const relatedLessons = useSelector((state) =>
    getLessonsByIds(state, assignmentLessonsId)
  );

  const navigate = useNavigate();
  const goToEditAssignment = () => {
    navigate(
      `/${user.profile.role}panel/editassignment/${item.info.assignmentId}`
    );
  };

  const deleteAssignment = async () => {
    if (window.confirm("are you sure to remove course")) {
      const realPath = `/lact/${item.info.groupCode}/assignments/${item.info.assignmentId}`;
      if (!item.info.assignmentId) throw new Error("assignment id not found");
      await deleteRealItem(realPath);
      toast.success("successfully delete assignment");
    }
  };

  const viewAssignment = () => {
    navigate(
      `/${user.profile.role}panel/assignments/viewassignment/${item.info.assignmentId}`
    );
  };

  return (
    <tr className="border-b h-14 flex items-center px-3 relative text-center">
      <ActionsMenu
        onView={viewAssignment}
        onDelete={deleteAssignment}
        onEdit={goToEditAssignment}
      />
      {/* <div className="absolute right-0 top-0 text-blue-600 me-1 mt-1 flex gap-x-3">
        <button onClick={viewAssignment}>
          <PreviewIcon className="text-blue-500" />
        </button>
        {((role === "instructor" &&
          user.profile.uid === item.info.creatorId) ||
          role === "admin" ||
          coursesInstructor.some((course) =>
            relatedLessons.some(
              (l) =>
                l.assignedCourses &&
                Object.keys(l.assignedCourses).includes(course)
            )
          )) && (
          <>
            <button onClick={goToEditAssignment}>
              Edit <FontAwesomeIcon icon={faPenToSquare} size="md" />
            </button>
            <button onClick={deleteAssignment}>
              <DeleteForeverOutlinedIcon className="text-cu-red" />
            </button>
          </>
        )}
      </div> */}
      <td className="w-1/4">
        <p>{item.info.assignmentId}</p>
      </td>

      <td className="w-1/4">
        <p>{item.info?.title || "---"}</p>
      </td>
      <td className="w-1/4 text-blue-700">
        <p>{item.info?.assignmentCreator}</p>
      </td>
    </tr>
  );
};

export default AssignmentRow;
