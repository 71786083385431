import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  List,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ShowAssignment from "./ShowAssignment";
import ShowLesson from "./ShowLesson";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { getAssignmentById } from "../../redux/assignmentsSlice";
import ReactQuill from "react-quill";

const EachLessonAcc = ({
  lesson,
  changeComp,
  index,
  expanded,
  setExpanded,
  courseId,
}) => {
  const assignmentId = lesson.assignedAssignments
    ? Object.keys(lesson.assignedAssignments)[0]
    : "";
  const assignment = useSelector((state) =>
    getAssignmentById(state, assignmentId)
  );
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const showAssignment = () => {
    changeComp(
      <ShowAssignment assignment={assignment} changeComp={changeComp} />
    );
  };
  const showLesson = (lesson) => {
    changeComp(
      <ShowLesson lesson={lesson} changeComp={changeComp} courseId={courseId} />
    );
  };

  return (
    <Accordion
      key={index}
      expanded={expanded === lesson.info.lessonId}
      onChange={handleChange(lesson.info.lessonId)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${lesson.info.lessonId}-content`}
        id={`${lesson.info.lessonId}-header`}
      >
        <Typography>{lesson.info.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          noWrap
          variant="body2"
          color="text.secondary"
          className="h-20"
        >
          <ReactQuill
            value={lesson.info.explain}
            readOnly={true}
            theme="bubble"
          />
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#002868" }}
            onClick={() => {
              showAssignment(assignment);
            }}
            disabled={!assignment}
          >
            Show Assignment
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#002868" }}
            onClick={() => {
              showLesson(lesson);
            }}
          >
            Show Lesson
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default EachLessonAcc;
