import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ChooseMultipleCategories({
  catName,
  setCatName,
  categories,
}) {
  function getStyles(name, catName, theme) {
    return {
      fontWeight: catName?.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }
  const theme = useTheme();
  // const [catName, setCatName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCatName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const flattenCategories = (categories) => {
    let result = [];
    categories.forEach((category) => {
      result.push(category);
      if (
        category.subCategories &&
        Object.values(category.subCategories).length > 0
      ) {
        result = result.concat(
          flattenCategories(Object.values(category.subCategories))
        );
      }
    });
    return result;
  };

  const flattenedData = flattenCategories(categories);

  return (
    <div className="w-full">
      <FormControl className="w-full">
        <InputLabel id="demo-multiple-chip-label">Select Categories</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={catName}
          onChange={handleChange}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="Select Categories"
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {flattenedData.map((category) => {
            const len = category.path.split("/").length;

            return (
              <MenuItem
                key={category.name}
                value={category.name}
                style={getStyles(category.name, catName, theme)}
              >
                {"-".repeat(len - 2)}
                {category.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
