import React from "react";
import AdminSideBar from "./adminLayout/AdminSideBar";
import AuthenticatedUserNav from "../shared/sharedComp/AuthenticatedUserNav";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import StudentSideBar from "./studentLayout/StudentSideBar";
import InstructorSideBar from "./instructorLayout/InstructorSideBar";
import Footer from "./Footer";

const PanelLayout = () => {
  const user = useSelector((state) => state.user);
  return (
    // <div className="flex flex-col h-full">
    <div className="flex bg-cu-blue min-h-full">
      {/* <div> */}
      {user.profile.role === "admin" && <AdminSideBar />}
      {user.profile.role === "instructor" && <InstructorSideBar />}
      {user.profile.role === "student" && <StudentSideBar />}
      {/* </div> */}
      {/* <div className="fixed w-full z-10 "> */}
      {/* </div> */}
      <div className="flex flex-col w-full h-full">
        <div className="steaky z-10">
          <AuthenticatedUserNav />
        </div>
        {/* <div className="grow relative top-12"> */}
        <div
          style={{ width: "calc(100% - 18rem)", height: "calc(100% - 3rem)" }}
          className="absolute top-12   bg-cu-blue w-full h-full  "
        >
          <div
            className="w-full h-full  px-4 rounded-tl-xl border-t-2 overflow-auto border-l-2 border-cu-red bg-white pt-5 pb-10"
            style={{ minHeight: "calc(100% - 6rem)" }}
          >
            {/* <div> */}
            <Outlet />
            {/* </div> */}
            {/* <div className="h-96 overflow-auto h-full"> */}
            {/* </div> */}
            {/* <Footer /> */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
    // </div>
  );
};

export default PanelLayout;
