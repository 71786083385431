import React from "react";
import { Container, Paper, Avatar, Typography, Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../redux/usersSlice";
import TimeShow from "../../../shared/utils/TimeShow";

const ReviewUser = () => {
  const { uid } = useParams();
  const user = useSelector((state) => getUserById(state, uid));
  const assignedCourses = user.assignedCourses
    ? Object.keys(user.assignedCourses)
    : [];
  return (
    <Container maxWidth="w-full">
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Box display="flex" justifyContent="center" mb={2}>
          <Avatar
            src={user.profile.photoUrl.url}
            alt={user.profile.name}
            style={{ width: 100, height: 100 }}
          />
        </Box>
        <Typography variant="h5" align="center" gutterBottom>
          {user.profile.name} {user.profile.family}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Student Number:</strong> {user.profile.studentNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>User ID:</strong> {user.profile.uid}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Email:</strong> {user.profile.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Address:</strong> {user?.address.country},{" "}
              {user?.address.state}, {user?.address.city},{" "}
              {user?.address.street}, {user?.address.unit}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Zip Code:</strong> {user?.address.zipCode}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Mobile Number:</strong> {user?.address.phoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Created At:</strong>{" "}
              <TimeShow date={new Date(user.profile.createAt)} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Last Login:</strong>{" "}
              <TimeShow date={new Date(user.profile.lastLogin)} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Course Enrolled:</strong>{" "}
              <div className="flex flex-col">
                {assignedCourses?.map((course) => (
                  <span>{course}</span>
                ))}
              </div>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ReviewUser;
