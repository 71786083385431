import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAllTables } from "../../../../redux/tablesSlice";
import { Box, Button, Grid, Slider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableLogo from "../TableLogo";
import ModalTable from "./ModalTable";

const ShowAllTables = () => {
  const tables = useSelector(getAllTables);
  const [size, setSize] = useState(100);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleSizeChange = (event, newValue) => {
    setSize(newValue);
  };
  return (
    <Box className="w-11/12 m-auto">
      <div className="flex">
        <Button onClick={() => setOpen(true)}>Add New</Button>
        <ModalTable open={open} setOpen={setOpen} />
      </div>
      <Grid container spacing={2}>
        {tables.map((table, index) => (
          <TableLogo table={table} />
        ))}
      </Grid>
    </Box>
  );
};

export default ShowAllTables;
