import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { getTableById, updateTable } from "../../../../redux/tablesSlice";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { createTableInDb } from "../../../../firebase.config";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormatTextdirectionRToLIcon from "@mui/icons-material/FormatTextdirectionRToL";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";
import GroupCodeSection from "../../sharedComp/GroupCodeSection";
import { serverTimestamp } from "firebase/database";
const AddTable = () => {
  const user = useSelector((state) => state.user);
  const { tableId } = useParams();
  const table = useSelector((state) => getTableById(state, tableId));
  const [groupCode, setGroupCode] = useState(
    table?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : "01")
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    title: table.info.title,
    description: table.info.explain,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [direction, setDirection] = useState(table.info.direction || "ltr");
  const [data, setData] = useState(table.data);
  const [selectedRow, setSelectedRow] = useState(null);

  const addedChangeToHeader = table?.headerData?.map((item) => ({
    ...item,
    muiEditTextFieldProps: ({ row, cell }) => ({
      onChange: (e) => {
        setData((prev) =>
          prev.map((line) => {
            return line[item.accessorKey] !== row.original[item.accessorKey]
              ? line
              : { ...row.original, [item.accessorKey]: e.target.value };
          })
        );
      },
    }),
  }));
  const [columns, setColumns] = useState(addedChangeToHeader);

  const [columnOrder, setColumnOrder] = useState(
    [...columns.map((c) => c.accessorKey), "mrt-row-actions"] //array of column ids (Initializing is optional as of v2.10.0)
  );

  const handleColumnChange = (index, key, value) => {
    const newColumns = JSON.parse(JSON.stringify(columns));
    newColumns[index].header = value;
    setColumns(newColumns);
  };

  const addColumn = () => {
    const newColumn = {
      accessorKey: uuidv4().replace(/-/g, "").substring(0, 10),
      header: `header ${columns.length}`,
      enableSorting: false,
    };
    setColumns([...columns, newColumn]);
  };

  const removeColumn = (index) => {
    setColumns(columns.filter((_, colIndex) => colIndex !== index));
  };

  const addRow = () => {
    const newRow = columns.reduce((acc, curr) => {
      acc[curr.accessorKey] = "data" + curr.header;
      return acc;
    }, {});
    newRow.id = uuidv4().replace(/-/g, "").substring(0, 10);
    setData([...data, newRow]);
  };

  const handleRemoveRow = (rowIndex) => {
    const updatedData = data.filter((_, index) => index !== rowIndex);
    setData(updatedData);
  };

  const handleSaveEdit = (rowIndex, values) => {
    const updatedData = [...data];
    updatedData[rowIndex] = values;
    setData(updatedData);
  };

  const handleCopyRow = (rowIndex) => {
    setSelectedRow(data[rowIndex]);
  };

  const handlePasteRow = (rowIndex) => {
    if (selectedRow) {
      const newData = [...data];
      newData.splice(rowIndex, 0, {
        ...selectedRow,
        id: uuidv4().replace(/-/g, "").substring(0, 10),
      });
      setData(newData);
      setSelectedRow(null);
    }
  };

  const submitTableToDb = async () => {
    const headerData = columns.map((item) => {
      const { muiEditTextFieldProps, ...modifiedHeader } = item;
      return modifiedHeader;
    });
    // console.log(headerData);
    const tableToSend = {
      info: {
        tableId,
        // visibility: "Public",
        creatorId: user.profile.uid,
        title: formData.title,
        explain: formData.description,
        createAt: table.info.createAt,
        editAt: serverTimestamp(),
        tableCreator: table.info.tableCreator,
        status: "published",
        direction,
        groupCode,

        // pdfTitle: "",
      },
      headerData,
      data,
      id: tableId,
    };
    try {
      await createTableInDb({ table: tableToSend });
      navigate(`/${user.profile.role}panel/tables`);
      dispatch(updateTable({ id: tableId, ...tableToSend }));
      toast.success("successfully add table");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  // const changeLtr = () => {
  //   setDirection("ltr")
  // }

  // const changeRtl = () => {
  //   setDirection("rtl")
  // }
  const handleDirection = (event, newAlignment) => {
    if (newAlignment !== null) {
      setDirection(newAlignment);
    }
  };
  return (
    <div className="w-11/12 m-auto" dir={direction}>
      <TextField
        size="small"
        label="Title"
        name="title"
        value={formData.title}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        fullWidth
        multiline
        rows={8}
        margin="normal"
      />
      <div>
        <Button onClick={addColumn} sx={{ background: `` }}>
          Add Column
        </Button>
        <Button onClick={addRow}>Add Row</Button>
        <ToggleButtonGroup
          value={direction}
          exclusive
          onChange={handleDirection}
          aria-label="text alignment"
        >
          <ToggleButton value="ltr" aria-label="left aligned">
            <FormatTextdirectionLToRIcon />
          </ToggleButton>
          <ToggleButton value="rtl" aria-label="right aligned">
            <FormatTextdirectionRToLIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        {user.profile.role === "admin" && (
          <GroupCodeSection groupCode={groupCode} setGroupCode={setGroupCode} />
        )}
      </div>

      <MaterialReactTable
        enableColumnResizing={true}
        columnResizeDirection={direction}
        direction={direction}
        editDisplayMode="table"
        // autoResetPageIndex={false}
        // enableRowActions
        // positionActionsColumn="last"
        enableSorting={false}
        enableColumnActions={false}
        // enableRowOrdering
        enableColumnOrdering
        columns={columns.map((column, index) => ({
          ...column,
          header: (
            <Box
              display="flex"
              alignItems="center"
              key={index}
              className={"group"}
            >
              {column.header === "ID" ? (
                <p>{column.header}</p>
              ) : (
                <TextField
                  value={column.header}
                  onChange={(e) =>
                    handleColumnChange(index, "header", e.target.value)
                  }
                  variant="standard"
                />
              )}
              <IconButton
                onClick={() => removeColumn(index)}
                className="invisible group-hover:visible"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ),
        }))}
        data={data}
        renderRowActions={({ row }) => (
          <Box className="group  p-2">
            <div className="invisible flex group-hover:visible">
              <Tooltip title="Copy Row">
                <IconButton onClick={() => handleCopyRow(row.index)}>
                  <span role="img" aria-label="copy">
                    📋
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip title="Paste Row">
                <IconButton onClick={() => handlePasteRow(row.index)}>
                  <span role="img" aria-label="paste">
                    📥
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Row">
                <IconButton onClick={() => handleRemoveRow(row.index)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Box>
        )}
        enableEditing
        getRowId={(row) => row.id}
        initialState={{ columnVisibility: { id: false } }}
        onColumnOrderChange={(newOrder) => {
          if (!newOrder) return;

          // newOrder.shift();
          setColumnOrder(newOrder);
          // newOrder.pop();

          // columns.forEach((ccc, index) => {
          //   const idx = newOrder.findIndex((str) => str === ccc?.accessorKey);
          //   if (idx !== -1) {
          //     colomnModified[idx] = columns[idx];
          //   }
          // });
          const colomnModified = columns.sort(
            (a, b) =>
              newOrder.indexOf(a.accessorKey) - newOrder.indexOf(b.accessorKey)
          );

          console.log(colomnModified);
          setColumns(colomnModified);
        }}
        state={{ columnOrder }}
        // onColumnOrderChange={setColumnOrder}
      />
      <Button onClick={submitTableToDb}>Submit</Button>
    </div>
  );
};

export default AddTable;

// import React, { useState } from "react";
// import MaterialTable from "material-table";
// import { ThemeProvider, createTheme } from "@mui/material";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   TextField,
// } from "@mui/material";

// const EditableTable = () => {
//   const [columns, setColumns] = useState([
//     { title: "Name", field: "name" },
//     { title: "Surname", field: "surname" },
//     { title: "Birth Year", field: "birthYear", type: "numeric" },
//     { title: "Birth City", field: "birthCity" },
//   ]);
//   const defaultMaterialTheme = createTheme();
//   const [data, setData] = useState([
//     {
//       name: "Mehmet",
//       surname: "Baran",
//       birthYear: 1987,
//       birthCity: "Istanbul",
//     },
//     {
//       name: "Zerya Betül",
//       surname: "Baran",
//       birthYear: 2017,
//       birthCity: "Istanbul",
//     },
//   ]);

//   const [open, setOpen] = useState(false);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   const [newTitle, setNewTitle] = useState("");

//   const handleClickOpen = (column) => {
//     setSelectedColumn(column);
//     setNewTitle(column.title);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleSave = () => {
//     const updatedColumns = columns.map((col) => {
//       if (col.field === selectedColumn.field) {
//         return { ...col, title: newTitle };
//       }
//       return col;
//     });
//     setColumns(updatedColumns);
//     handleClose();
//   };

//   return (
//     <div>
//       <ThemeProvider theme={defaultMaterialTheme}>
//         <MaterialTable
//           columns={[
//             { title: "Adı", field: "name" },
//             { title: "Soyadı", field: "surname" },
//             { title: "Doğum Yılı", field: "birthYear", type: "numeric" },
//             {
//               title: "Doğum Yeri",
//               field: "birthCity",
//               lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
//             },
//           ]}
//           data={[
//             {
//               name: "Mehmet",
//               surname: "Baran",
//               birthYear: 1987,
//               birthCity: 63,
//             },
//           ]}
//           title="Demo Title"
//         />
//       </ThemeProvider>
//     </div>
//   );
// };

// export default EditableTable;
