import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getUserInfo } from "../firebase.config";

const userInitialState = {
  currentUser: null,
  profile: {
    name: "",
    email: "",
    family: "",
    createAt: "",
    role: "",
    uid: "",
    photoUrl: "",
    lastLogin: "",
    accountCreator: "",
    courses: [],
  },
  address: {
    privateEmail: "",
    country: "",
    city: "",
    street: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    homeContactNumber: "",
    unit: "",
  },
  documents: {
    registeryForm: {},
  },
};

export const getUserProfile = createAsyncThunk(
  "/getuserprofile",
  async (currentUser) => {
    const idTokenResult = await currentUser.getIdTokenResult();
    const groupCode = idTokenResult.claims.groupCode;
    console.log(idTokenResult.claims.groupCode);
    try {
      const userInfo = await getUserInfo(currentUser.uid, groupCode);
      // console.log(userInfo)
      return { userInfo, currentUser };
    } catch (error) {
      toast.error(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    // currentUserRedux: (state, action) => {
    //     return ({ ...action.payload })
    // },

    logoutUser: (state) => {
      return userInitialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        // state.profile = payload.userInfo.profile;
        // state.address = payload.userInfo.address;
        // state.documents = payload.userInfo.documents;
        // state.currentUser = payload.currentUser;
        // state.assignedCourses = payload.userInfo.assignedCourses;
        // state.importedInfo = payload.userInfo.importedInfo;
        // state.reportCards = payload.userInfo.reportCards;
        return { ...payload.userInfo, currentUser: payload.currentUser };
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        toast.error("unable to load other user information");
      });
  },
});

export const { logoutUser, setUserInfo } = userSlice.actions;
export default userSlice.reducer;

// import {
//   createSlice,
//   createAsyncThunk,
//   createEntityAdapter,
// } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
// import { getUserInfo } from "../firebase.config";

// // Create an entity adapter for users
// const usersAdapter = createEntityAdapter();

// const userInitialState = usersAdapter.getInitialState({
//   currentUser: null,
//   profile: {
//     name: "",
//     email: "",
//     family: "",
//     createAt: "",
//     role: "",
//     uid: "",
//     photoUrl: "",
//     lastLogin: "",
//     accountCreator: "",
//     courses: [],
//   },
//   address: {
//     privateEmail: "",
//     country: "",
//     city: "",
//     street: "",
//     state: "",
//     zipCode: "",
//     phoneNumber: "",
//     homeContactNumber: "",
//     unit: "",
//   },
//   documents: {
//     registeryForm: {},
//   },
//   reportCart: [],
// });

// export const getUserProfile = createAsyncThunk(
//   "/getuserprofile",
//   async (currentUser) => {
//     try {
//       const userInfo = await getUserInfo(currentUser.uid);
//       return { userInfo, currentUser };
//     } catch (error) {
//       toast.error(error.message);
//     }
//   }
// );

// const userSlice = createSlice({
//   name: "user",
//   initialState: userInitialState,
//   reducers: {
//     logoutUser: (state) => {
//       return userInitialState;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getUserProfile.fulfilled, (state, { payload }) => {
//         usersAdapter.setAll(state, [payload.userInfo]);
//         state.currentUser = payload.currentUser;
//       })
//       .addCase(getUserProfile.rejected, (state, action) => {
//         toast.error("Unable to load user information");
//       });
//   },
// });

// export const { logoutUser } = userSlice.actions;
// export default userSlice.reducer;
