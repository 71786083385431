import React, { useState, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const ActionsMenu = ({ onView, onEdit, onDelete }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="absolute right-0 top-0 text-blue-600 h-full me-1 flex items-center cursor-pointer">
      <Button onClick={openMenu}>
        <MoreHorizIcon className="text-cu-blue" />
      </Button>
      {menuOpen && (
        <div
          ref={menuRef}
          className="absolute top-full right-0 bg-white shadow-lg p-1 z-40 w-32 flex flex-col gap-y-2 items-start rounded-xl border"
        >
          <Button
            onClick={onView}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
            }}
            className="gap-x-2 w-full "
          >
            <PreviewIcon className="text-blue-500" sx={{ width: "20px" }} />
            <span>Review</span>
          </Button>
          <Button
            onClick={onEdit}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
            }}
            className="w-full gap-x-2"
          >
            <EditIcon sx={{ width: "20px" }} />
            <span>Edit</span>
          </Button>
          <Button
            onClick={onDelete}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
              color: "#bf0a30",
            }}
            className=" w-full gap-x-2"
          >
            <DeleteForeverOutlinedIcon sx={{ width: "20px" }} /> Delete
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActionsMenu;
