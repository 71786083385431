import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const assignmentsAdapter = createEntityAdapter();

const initialState = assignmentsAdapter.getInitialState();

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    addAssignments: assignmentsAdapter.setAll,
    createEmptyAssignment: assignmentsAdapter.setOne,
    removeNotPublishedAssignment: assignmentsAdapter.removeOne,
    updatePdfId: (state, { payload }) => {
      const { assignmentId, pdf } = payload;
      const entity = state.entities[assignmentId];
      entity.files.pdf = pdf;
    },
    updateImages: (state, { payload }) => {
      const { assignmentId, images } = payload;
      const entity = state.entities[assignmentId];
      entity.files.images = images;
    },
    updateVideoId: (state, { payload }) => {
      const { assignmentId, video } = payload;
      const entity = state.entities[assignmentId];
      entity.files.videoId = video;
    },
    uploadResourceIdAssignment: (state, { payload }) => {
      const { assignmentId, resourceId, item } = payload;
      const entity = state.entities[assignmentId];
      entity.resources[resourceId] = item;
    },
  },
});

export const {
  addAssignments,
  createEmptyAssignment,
  removeNotPublishedAssignment,
  updatePdfId,
  updateVideoId,
  updateImages,
  uploadResourceIdAssignment,
} = assignmentsSlice.actions;
export const getAllAssignments = (state) =>
  assignmentsAdapter
    .getSelectors()
    .selectAll(state.assignments)
    .sort((a, b) => b.info.createAt - a.info.createAt);

export const getAssignmentById = (state, assignmentId) =>
  assignmentsAdapter.getSelectors().selectById(state.assignments, assignmentId);

export const getAssignmentEntities = (state) =>
  assignmentsAdapter.getSelectors().selectEntities(state.assignments);

export const getAssignmentsByIds = createSelector(
  [getAssignmentEntities, (state, ids) => ids],
  (entities, ids) =>
    ids
      .map((id) => entities[id])
      .sort((a, b) => b.info.createAt - a.info.createAt)
);

export default assignmentsSlice.reducer;
