import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteRealItem,
  deleteStorageRecursively,
} from "../../../firebase.config";
import { toast } from "react-toastify";
import PreviewIcon from "@mui/icons-material/Preview";
import { Avatar } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import ActionsMenu from "../../shared/sharedComp/ActionsMenu";
const UserRow = ({ item }) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user);
  const goToEditUser = () => {
    // dispatch(getSelectedUser({ item }));
    navigate(`/adminpanel/editmember/${item.id}/createemail`);
  };
  const profile = item.profile;

  const deleteUser = async () => {
    if (!item.profile.uid) throw new Error("id is not exists");
    if (window.confirm("are you sure to remove user")) {
      const realPath = `/lact/${item.profile.groupCode}/users/${item.profile.uid}`;
      const notificationPath = `/notifications/${item.profile.uid}`;
      const storagePath = `/members/${item.profile.role}/${item.profile.uid}`;
      const assignCourses = item.assignCourses
        ? Object.keys(item.assignCourses)
        : [];
      const coursesRemPromise = assignCourses.map((courseId) => {
        if (!courseId || !item.profile.uid) throw new Error("id not found");
        const path = `/lact/${item.profile.groupCode}/courses/${courseId}/${item.profile.uid}`;
        return deleteRealItem(path);
      });
      try {
        await Promise.all(coursesRemPromise);
        await deleteRealItem(realPath);
        await deleteRealItem(notificationPath);
        await deleteStorageRecursively(storagePath);

        const idToken = await currentUser.getIdToken();
        const response = await axios.post(
          "https://us-central1-cyrus-university-website.cloudfunctions.net/deleteEmailAddress",
          {
            uid: item.profile.uid,
          },
          {
            headers: {
              Authorization: "Bearer " + idToken,
              "Content-Type": "application/json",
            },
          }
        );
        // if (!response.ok) {
        const res = response.data;
        console.log(res);
        // throw new Error(errorData.message || "Something went wrong");
        // }

        toast.success("successfully delete user");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  };

  const viewUser = () => {
    navigate(`viewuser/${item.profile.uid}`);
  };

  return (
    <tr className="border-b h-14 flex items-center gap-x-10 px-10 relative">
      {/* <div className="absolute right-0 top-0 text-blue-600 me-1 mt-1 flex gap-x-3">
        <button onClick={viewUser}>
          <PreviewIcon className="text-blue-500" />
        </button>
        <button onClick={goToEditUser}>
          Edit <FontAwesomeIcon icon={faPenToSquare} size="md" />
        </button>
        <button onClick={deleteUser}>
          <DeleteForeverOutlinedIcon className="text-cu-red" />
        </button>
      </div> */}
      <ActionsMenu
        onView={viewUser}
        onEdit={goToEditUser}
        onDelete={deleteUser}
      />

      {/* <p className="absolute left-2 top-0 text-slate-600 me-3 mt-3 ">
        <span>{profile.role}: </span>
        <span className="text-xs">{profile.uid}</span>
      </p> */}

      <td className="w-1/5 flex items-center">
        <Avatar
          className="rounded-full border w-14 h-14 object-contain"
          src={profile?.photoUrl?.url}
          alt={profile?.name}
        />
        <p className="ms-4">
          {profile?.name} {profile?.family}
        </p>
      </td>
      <td className="w-1/5">
        <p>{profile.email}</p>
      </td>
      <td className="w-1/5">
        <p>{profile.role}</p>
      </td>
      <td className="w-1/5">
        {profile.role === "student" ? (
          <div>
            {" "}
            <p>{profile.studentNumber}</p>
          </div>
        ) : (
          <p>---</p>
        )}
      </td>
    </tr>
  );
};

export default UserRow;
