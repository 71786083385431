import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShowAllImages from "./gallery/ShowAllImages";
import ShowAllPdf from "./library/ShowAllPdf";
import ShowAllVideos from "./videos/ShowAllVideos";

const ShowResources = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const lastpart =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const [selectedButton, setSelectedButton] = useState(lastpart);
  const handleButtonChange = (event, newButton) => {
    if (newButton !== null) {
      setSelectedButton(newButton);
      navigate(newButton);
    }
  };

  return (
    <div>
      <Box className={"w-11/12 m-auto"}>
        <ToggleButtonGroup
          value={selectedButton}
          exclusive
          onChange={handleButtonChange}
          aria-label="button selection"
          sx={{ marginBottom: 2 }} // Add some margin at the bottom
        >
          <ToggleButton value="pdf" aria-label="PDFs">
            PDFs
          </ToggleButton>
          <ToggleButton value="video" aria-label="Videos">
            Videos
          </ToggleButton>
          <ToggleButton value="image" aria-label="Images">
            Images
          </ToggleButton>
          {/* <ToggleButton value="tables" aria-label="Tables">
          Tables
        </ToggleButton> */}
        </ToggleButtonGroup>
      </Box>
      {selectedButton === "pdf" && <ShowAllPdf />}
      {selectedButton === "image" && <ShowAllImages />}
      {selectedButton === "video" && <ShowAllVideos />}
    </div>
  );
};

export default ShowResources;
