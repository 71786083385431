import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  deleteRealItem,
  deleteStorageRecursively,
  postPdfDataToDb,
} from "../../../../firebase.config";
// import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage"
import { ReactComponent as PostLoadingComp } from "../../../../images/svg/postsvgLoading.svg";
import AddNamesInput from "../../sharedComp/AddNamesInput";
import { toast } from "react-toastify";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { serverTimestamp } from "firebase/database";
import { useSelector } from "react-redux";
import { selectPdfById } from "../../../../redux/allPdfsSlice";
import { getDocument } from "pdfjs-dist";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { GlobalWorkerOptions } from "pdfjs-dist";

import { pdfjs } from "react-pdf";
import GroupCodeSection from "../../sharedComp/GroupCodeSection";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LoadingButton from "../../../../shared/comp/LoadingButton";
GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const AddNewPdf = () => {
  const inputRef = useRef();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { pdfId: paramsPdfId } = useParams();
  const [pdfId, setPdfId] = useState(paramsPdfId);
  const [selectedFile, setSelectedFile] = useState("");
  const pdf = useSelector((state) => selectPdfById(state, pdfId));
  const [numberOfPdfPage, setNumberOfPdfPages] = useState(
    pdf?.file?.pagesCount || 0
  );

  const [name, setName] = useState(pdf?.info?.name || "");
  const [category, setCategory] = useState(pdf?.info?.category || "");
  const [postLoading, setPostLoading] = useState(false);
  //   const [numOfPages, setNumOfPages] = useState(0);
  const [explain, setExplain] = useState(pdf?.info?.explain || "");
  const [authors, setAuthors] = useState(pdf?.info?.authors || []);
  const [labels, setLabels] = useState(pdf?.info?.labels || []);
  //   const [imagePath, setImagePath] = useState("");
  const [imageUrl, setImageUrl] = useState(pdf?.file?.url?.image || "");
  const [imagePath, setImagePath] = useState(pdf?.file?.path?.image || "");
  const [pdfPath, setPdfPath] = useState(pdf?.file?.path?.pdf || "");
  const canvasRef = useRef(null);
  const [imageBlob, setImageBlob] = useState("");
  const storage = getStorage();
  const [progressBarContent, setProgressBarContent] = useState(0);
  const [localUrl, setLocalUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState(pdf?.file?.url?.pdf || "");
  const [groupCode, setGroupCode] = useState(
    pdf?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : "")
  );
  useEffect(() => {
    if (!pdfId) {
      const id = uuidv4();
      setPdfId(id);
    }
  }, [pdf, pdfId]);

  const storeImageFile = async (pdfId) => {
    if (!pdfId) return;
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `/resources/pdf/${pdfId}/image.png`);
      const uploadTask = uploadBytesResumable(storageRef, imageBlob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("uploading image ...");
        },
        (error) => {
          toast.error(`error in upload image${error}`);
          reject(error);
        },
        () => {
          console.log("image successfully uploaded");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            setImagePath(`/resources/pdf/${pdfId}/image.png`);
            setImageUrl(downloadURL);
            // setImageUrlOnStorage(downloadURL);
          });
        }
      );
    });
  };

  const storePdfFile = () => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, pdfPath);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBarContent(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          toast("file successfully uploaded to storage");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setPdfUrl(downloadURL);
            setPdfPath(`/resources/pdf/${pdfId}/pdf`);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  useEffect(() => {
    if (!localUrl) return;
    const saveLocalUrl = async () => {
      const pdf = await getDocument(localUrl).promise;
      const page = await pdf.getPage(1);
      setNumberOfPdfPages(pdf.numPages);

      // Increase the scale factor for better quality
      const scale = 2; // Adjust this value as needed
      const viewport = page.getViewport({ scale });

      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const imgDataUrl = canvas.toDataURL("image/png");
      const imageRes = await fetch(imgDataUrl);
      const imageBlobI = await imageRes.blob();
      setImageBlob(imageBlobI);
    };
    saveLocalUrl();
  }, [localUrl]);

  useEffect(() => {
    if (!imageBlob) return;
    const saveDataInDb = async () => {
      setPostLoading(true);
      try {
        const pdfLink = await storePdfFile();
        const imageLink = await storeImageFile(pdfId);
        const pdfToUpload = {
          info: {
            pdfId,
            name,
            category,
            explain,
            authors,
            labels,
            createAt: serverTimestamp(),
            creatorId: user.profile.uid,
            groupCode,
          },
          file: {
            path: {
              image: imagePath,
              pdf: pdfPath,
            },
            url: { image: imageLink, pdf: pdfLink },
            pagesCount: numberOfPdfPage,
          },
          id: pdfId,
        };
        await postPdfDataToDb(pdfToUpload, groupCode);
        setPostLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("upload failed");
      }
    };
    saveDataInDb();
  }, [imageBlob]);

  const submitData = async (e) => {
    e.preventDefault();
    try {
      setPostLoading(true);
      const response = await postPdfDataToDb({
        info: {
          pdfId,
          name,
          category,
          explain,
          authors,
          labels,
          createAt: serverTimestamp(),
          creatorId: user.profile.uid,
          groupCode,
        },
        file: {
          path: {
            image: imagePath,
            pdf: pdfPath,
          },
          url: { image: imageUrl, pdf: pdfUrl },
          pagesCount: numberOfPdfPage,
        },
        id: pdfId,
      });
      toast(response.message);
      setPostLoading((prev) => !prev);
      navigate(`/${user.profile.role}panel/resources/pdf`);
    } catch (error) {
      toast.error(error.message);
    }
  };
  //input for second time not sorkign
  //delete dont close modal
  const onInputFile = async (e) => {
    if (!e.target.files.length || !pdfId) return;
    if (!groupCode) {
      toast.error("specify groupcode first");
      e.target.value = "";
      return;
    }
    setPdfPath(`/resources/pdf/${pdfId}/pdf`);
    setImagePath(`/resources/pdf/${pdfId}/image.png`);
    const lclUrl = URL.createObjectURL(e.target.files[0]);

    setLocalUrl(lclUrl);
    setSelectedFile(e.target.files[0]);
  };

  const removeOneSelectedFile = async () => {
    if (!pdfId || !groupCode) throw new Error("id not found");
    if (window.confirm("are you want to proceed removing pdf ?")) {
      try {
        await deleteRealItem(`/resources/${groupCode}/pdf/${pdfId}`);
        await deleteStorageRecursively(`/resources/pdf/${pdfId}`);
        setSelectedFile("");
        setProgressBarContent("");
        setImageUrl("");
        setImagePath("");
        setPdfUrl("");
        setPdfPath("");
        setLocalUrl("");
        inputRef.current.value = "";
        toast.success("successfully deleted");
      } catch (error) {
        toast.error(error);
      }
    }
  };
  return (
    // <Container maxWidth="md">
    <form onSubmit={submitData} className="w-11/12 m-auto">
      <div style={{ padding: "10px", marginTop: "20px" }}>
        <Box className="flex items-center gap-x-3 mb-5">
          <Typography>PDF ID : {pdfId}</Typography>
          <Button component="label" role={undefined} tabIndex={-1} size="small">
            <CloudUploadIcon
              sx={{ fontSize: "40px" }}
              className="text-cu-blue"
            />
            <VisuallyHiddenInput
              type="file"
              onChange={onInputFile}
              accept={".pdf"}
              multiple={false}
              ref={inputRef}
            />
          </Button>
          <div className="ml-auto w-80">
            {user.profile.role === "admin" && (
              <GroupCodeSection
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                disabled={pdf?.info?.groupCode}
              />
            )}
          </div>
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Name"
                variant="outlined"
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Category"
                variant="outlined"
                fullWidth
                // required
              />
            </Grid>
          </Grid>

          <div className="flex justify-between">
            <Box mt={5} display="flex" flexDirection="column" gap={2}>
              <AddNamesInput
                placeholder="Authors"
                parentList={authors}
                setParentList={setAuthors}
              />
              <AddNamesInput
                placeholder="Labels"
                parentList={labels}
                setParentList={setLabels}
              />
            </Box>
            {(selectedFile || pdfUrl) && (
              <div className="flex flex-col items-center border p-3 mt-3">
                <div className="m-auto text-center">
                  {pdfUrl && (
                    <span
                      style={{ cursor: "pointer" }}
                      className="text-red-600"
                      onClick={(e) => removeOneSelectedFile()}
                    >
                      X
                    </span>
                  )}

                  <div>
                    <canvas
                      ref={canvasRef}
                      className={`rounded-md m-auto hidden `}
                    ></canvas>

                    {/* {!imageUrl && localUrl && (
                      <div className="flex items-center justify-center">
                        <img
                          width={200}
                          height={200}
                          src={localUrl}
                          alt="Selected frame"
                        />
                      </div>
                    )} */}
                    {imageUrl && (
                      <div className="flex items-center justify-center">
                        <img
                          width={200}
                          height={200}
                          src={imageUrl}
                          alt="Selected frame"
                        />
                      </div>
                    )}
                    {!imageUrl && (
                      <div
                        className={`w-full rounded-full bg-slate-200 mt-2 ${
                          !progressBarContent ? "hidden" : "block"
                        } }`}
                      >
                        <PictureAsPdfIcon sx={{ fontSize: "50px" }} />
                        <div
                          className={` text-center bg-blue-500 rounded-full ${
                            progressBarContent === 100 && "bg-green-500"
                          }`}
                          style={{ width: `${progressBarContent}%` }}
                        >
                          {Math.floor(progressBarContent)}%
                        </div>
                      </div>
                    )}
                    {numberOfPdfPage && (
                      <p className="text-center py-5">
                        {" "}
                        {numberOfPdfPage} pages
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Box>

        {/* <Box mt={5} display="flex" flexDirection="column" gap={2}>
          <AddNamesInput
            placeholder="Authors"
            parentList={authors}
            setParentList={setAuthors}
          />
          <AddNamesInput
            placeholder="Labels"
            parentList={labels}
            setParentList={setLabels}
          />
        </Box> */}

        <TextField
          value={explain}
          onChange={(e) => setExplain(e.target.value)}
          label="Explanation"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />
        {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          className="m-auto"
          disabled={!pdfUrl || postLoading}
        >
          {postLoading && <PostLoadingComp />}
          Submit
        </Button> */}
        <div className="w-96 m-auto">
          <LoadingButton
            loading={postLoading}
            disabled={!pdfUrl}
            name={"SUBMIT"}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNewPdf;
