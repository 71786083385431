import React, { useEffect, useState } from "react";
import {
  Paper,
  Button,
  Box,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AttachFile,
  Image,
  VideoLibrary,
  TableChart,
} from "@mui/icons-material";
import ShowResources from "../../resourses/ShowResources";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogoItem from "../../resourses/LogoItem";
import { useSelector } from "react-redux";
import { selectPdfById } from "../../../../redux/allPdfsSlice";
import { selectVideoById } from "../../../../redux/allVideosSlice";
import { selectImageById } from "../../../../redux/allImagesSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { db, removeSinleRealPath } from "../../../../firebase.config";
import { useDispatch } from "react-redux";
import { ref, set } from "firebase/database";
import ShowAllTables from "../../resourses/tables/ShowAllTables";
import { getTableById } from "../../../../redux/tablesSlice";
import TableLogo from "../../resourses/TableLogo";

const AddResources = ({
  sectionName,
  section,
  resource,
  sectionId,
  groupCode,
}) => {
  const initialResources =
    (section.resources && Object.values(section.resources)) || [];
  const [selectTableRs, setSelectTableRs] = useState(false);
  const { resourceId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const pdf = useSelector((state) => selectPdfById(state, resource.id));
  const video = useSelector((state) => selectVideoById(state, resource.id));
  const image = useSelector((state) => selectImageById(state, resource.id));
  const table = useSelector((state) => getTableById(state, resource.id));
  const user = useSelector((state) => state.user);
  const [itemProps, setItemProps] = useState({
    src: "",
    item: "",
    id: "",
    type: "",
  });

  useEffect(() => {
    if (pdf) {
      setItemProps({
        src: pdf.file?.url.image,
        item: pdf,
        id: pdf.info.pdfId,
        type: "pdf",
      });
    } else if (image) {
      setItemProps({
        src: image?.files && Object.values(image?.files)[0].url,
        item: image,
        id: image.info.imageId,
        type: "image",
      });
    } else if (video) {
      setItemProps({
        src: video.file?.url.image,
        item: video,
        id: video.info.videoId,
        type: "video",
      });
    }
  }, [image, pdf, video]);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (
      location.pathname.includes(
        `addnew${sectionName.substring(0, sectionName.length - 1)}`
      )
    ) {
      navigate(
        `/${user.profile.role}panel/addnew${sectionName.substring(
          0,
          sectionName.length - 1
        )}/${sectionId}`
      );
    } else if (
      location.pathname.includes(
        `edit${sectionName.substring(0, sectionName.length - 1)}`
      )
    ) {
      navigate(
        `/${user.profile.role}panel/edit${sectionName.substring(
          0,
          sectionName.length - 1
        )}/${sectionId}`
      );
    }
    setOpen(false);
  };

  //   const handleAttachImage = () => {
  //     // Handle attaching image
  //     console.log("Attach Image");
  //   };

  //   const handleAttachVideo = () => {
  //     // Handle attaching video
  //     console.log("Attach Video");
  //   };

  const handleAttachTable = () => {
    setSelectTableRs(true);
    navigate(`${resource.resourceId}`);
    setOpen(true);
  };
  const handleAttachFile = () => {
    setSelectTableRs(false);
    navigate(`${resource.resourceId}`);
    setOpen(true);
  };

  const removeFile = async () => {
    if (!sectionId || !resource.resourceId || !sectionName) {
      toast.error("id doesnt exists");
      return;
    }
    if (window.confirm("are you sure remove this file from resource ?")) {
      try {
        const filteredResources = initialResources.filter(
          (rs) => rs.resourceId !== resource.resourceId
        );
        const newResources = filteredResources.map((rs) =>
          resource.resourceId > rs.resourceId
            ? rs
            : { ...rs, resourceId: rs.resourceId - 1 }
        );
        const objectResource = newResources.reduce((acc, curr) => {
          acc[curr.resourceId] = curr;
          return acc;
        }, {});
        const path = `/lact/${groupCode}/${sectionName}/${sectionId}/resources`;
        const docRef = ref(db, path);
        await set(docRef, objectResource);
        // setResources((prev) =>
        //   prev.filter((eachRe) => eachRe.resourceId === resource.resourceId)
        // );

        toast.success("successfully remove path");
      } catch (error) {
        console.log(error);
        toast.error(error.messgae);
      }
    }
  };
  return (
    <Paper
      elevation={1}
      sx={{
        padding: 5,
        marginBottom: "20px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ top: "10px", left: "10px", position: "absolute" }}>
        {resource.resourceId}
      </Typography>
      <IconButton
        sx={{ top: "10px", right: "10px", position: "absolute" }}
        onClick={removeFile}
      >
        <DeleteIcon className="text-cu-red" />
      </IconButton>
      {resource.id ? (
        // <div onClick={() => navigate(resource.resourceId)}>
        //   {" "}
        table ? (
          <TableLogo table={table} />
        ) : (
          <LogoItem
            src={itemProps.src}
            size={300}
            item={itemProps.item}
            id={itemProps.id}
            type={itemProps.type}
          />
        )
      ) : (
        // </div>
        <Box sx={{ gap: 2, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            startIcon={<AttachFile />}
            onClick={handleAttachFile}
          >
            Attach File
          </Button>
          <Button
            variant="contained"
            startIcon={<TableChart />}
            onClick={handleAttachTable}
          >
            Attach Table
          </Button>
        </Box>
      )}

      <Modal
        open={open}
        aria-labelledby="modal-file"
        aria-describedby="modal-file"
        sx={{ zIndex: "20" }}
      >
        <div>
          <Box
            sx={{
              position: "relative",
              right: "0px",
              margin: "auto",
              // transform: "translate(-10%, -10%)",
              width: "100%",
              height: "100vh",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
            }}
          >
            <Typography className="text-center">
              Select {sectionName.substring(0, sectionName.length - 1)}{" "}
              Resources For {resourceId}
            </Typography>
            {selectTableRs ? <ShowAllTables /> : <ShowResources />}
            <Button
              sx={{ position: "absolute", top: "10px", left: "10px" }}
              onClick={handleClose}
            >
              Back to {sectionName.substring(0, sectionName.length - 1)}
            </Button>
          </Box>
        </div>
      </Modal>
    </Paper>
  );
};

export default AddResources;
