import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ShowStudentProfile from "../../student/profile/ShowStudentProfile";
import ShowCourse from "../../student/ShowCourse";
import ShowLesson from "../../student/ShowLesson";
import { onValue, ref } from "firebase/database";
import { db } from "../../../firebase.config";
import { useDispatch } from "react-redux";
import { addCourses } from "../../../redux/coursesSlice";
import { addlessons } from "../../../redux/lessonsSlice";
import { addAssignments } from "../../../redux/assignmentsSlice";
import ShowAssignment from "../../student/ShowAssignment";

import Shahname from "../../../pages/resourses/Shahname";
import Parsiban from "../../../pages/resourses/Parsiban";
import Pubmed from "../../../pages/resourses/Pubmed";
import AvestaKeyboard from "../../../pages/resourses/AvestaKeyboard";
import GradeToStudent from "../../../panels/shared/reportCards/GradeToStudent";
import { addtables } from "../../../redux/tablesSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { addUsers } from "../../../redux/usersSlice";
import { setImages } from "../../../redux/allImagesSlice";
import { setPdfs } from "../../../redux/allPdfsSlice";
import { setVideos } from "../../../redux/allVideosSlice";
import PanelLayout from "../PanelLayout";
import ShowCourses from "../../student/ShowCourses";
import ShowAllGroups from "../../shared/group/ShowAllGroups";
import ReviewGroup from "../../shared/group/ReviewGroup";
import Library from "../../shared/Library/Library";
const StudentPanel = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const allGroupInfo = ref(db, "/lact");
  //   return onValue(
  //     allGroupInfo,
  //     (snap) => {
  //       if (snap.val()) {
  //         const lact = [...Object.values(snap.val())];
  //         const courses = lact
  //           .map((l) => {
  //             if (l.courses) return Object.values(l.courses);
  //           })
  //           .flat();
  //         const lessons = lact
  //           .map((l) => {
  //             if (l.lessons) return Object.values(l.lessons);
  //           })
  //           .flat();
  //         const assignments = lact
  //           .map((l) => {
  //             if (l.assignments) return Object.values(l.assignments);
  //           })
  //           .flat();
  //         const tables = lact
  //           .map((l) => {
  //             if (l.tables) return Object.values(l.tables);
  //           })
  //           .flat();

  //         courses && dispatch(addCourses(courses));
  //         lessons && dispatch(addlessons(lessons));
  //         assignments && dispatch(addAssignments(assignments));
  //         tables && dispatch(addtables(tables));
  //       }
  //     },
  //     (error) => {
  //       toast.error(error.message);
  //       console.log(error);
  //     }
  //   );
  // }, []);
  useEffect(() => {
    const groupCodes = [user.profile.groupCode];
    const assignedCoursesGroups = user.assignedCourses
      ? Object.values(user.assignedCourses)
      : [];
    assignedCoursesGroups.forEach((group) => {
      if (!groupCodes.includes(group)) {
        groupCodes.push(group);
      }
    });

    const listeners = groupCodes.map((groupCode) => {
      const coursesRef = ref(db, `/lact/${groupCode}`);
      return onValue(
        coursesRef,
        (snap) => {
          if (snap.val()) {
            dispatch(addCourses(snap.val().courses));
            dispatch(addlessons(snap.val().lessons));
            dispatch(addAssignments(snap.val().assignments));
            dispatch(addtables(snap.val().tables));
            dispatch(addUsers(snap.val().users));
          }
        },
        (error) => {
          toast.error(error.message);
          console.log(error);
        }
      );
    });

    // Cleanup function to remove all listeners
    return () => {
      listeners.forEach((unsubscribe) => unsubscribe());
    };
  }, []);
  useEffect(() => {
    const groupCodes = [user.profile.groupCode];
    const assignedCoursesGroups = user.assignedCourses
      ? Object.values(user.assignedCourses)
      : [];
    assignedCoursesGroups.forEach((group) => {
      if (!groupCodes.includes(group)) {
        groupCodes.push(group);
      }
    });

    const listeners = groupCodes.map((groupCode) => {
      const resRef = ref(db, `/resources/${groupCode}`);
      return onValue(resRef, (snap) => {
        if (snap.val()) {
          const resource = snap.val();
          resource.image && dispatch(setImages(resource.image));
          resource.pdf && dispatch(setPdfs(resource.pdf));
          resource.video && dispatch(setVideos(resource.video));
        }
      });
    });

    // Cleanup function to remove all listeners
    return () => {
      listeners.forEach((unsubscribe) => unsubscribe());
    };
  }, []);
  // useEffect(() => {
  //   const usersRef = ref(db, `/lact/${user.profile.groupCode}/users`);
  //   return onValue(
  //     usersRef,
  //     (snap) => {
  //       const users = [];
  //       if (snap.val()) {
  //         snap.forEach((childsnap) => {
  //           if (childsnap.val().profile.groupCode === user.profile.groupCode) {
  //             users.push({ id: childsnap.key, ...childsnap.val() });
  //           }
  //         });
  //         console.log(users);
  //         dispatch(addUsers(users));
  //       }
  //     },
  //     (error) => {
  //       toast.error(error.message);
  //       console.log(error);
  //     }
  //   );
  // }, []);

  return (
    <Routes>
      <Route path="/" element={<PanelLayout />}>
        <Route path="profile" element={<ShowStudentProfile />} />
        <Route path="groups" element={<ShowAllGroups />} />
        <Route path="viewgroup/:groupId" element={<ReviewGroup />} />
        <Route path="reportcard" element={<GradeToStudent />} />
        {/* <Route path="profile/addnewessay/:pdfId?" element={<AddNewPdf />} /> */}
        <Route path="courses" element={<ShowCourses />} />
        <Route path="courses/viewcourse/:courseId?" element={<ShowCourse />} />
        <Route path="courses/:courseId/:lessonId?" element={<ShowLesson />} />
        <Route
          path="assignments/:courseId/:assignmentId?"
          element={<ShowAssignment />}
        />
        <Route path="library" element={<Library />} />
        <Route path="tools/shahname" element={<Shahname />} />
        <Route path="tools/parsiban" element={<Parsiban />} />
        <Route path="tools/pubmed" element={<Pubmed />} />
        <Route path="tools/avestakb" element={<AvestaKeyboard />} />
      </Route>
    </Routes>
  );
};

export default StudentPanel;
