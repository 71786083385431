import React, { useEffect, useState } from "react";
import { db } from "../../../firebase.config";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
// import AddNewMember from "../members/AddNewMember";

// import AddNewCourse from './course/AddNewCourse';
// import ShowAllUsers from "../members/ShowAllUsers";

import ShowAllCourses from "../../shared/courses/ShowAllCourses";
import AddNewLesson from "../../shared/lessons/AddNewLesson";
import ShowAllLessons from "../../shared/lessons/ShowAllLessons";
import AddNewNotification from "../../shared/notifications/AddNewNotification";
import AddNewPdf from "../../shared/resourses/library/AddNewPdf";
// import ReviewUser from "../members/ReviewUser";
import ReviewCourse from "../../shared/courses/ReviewCourse";
import ReviewLesson from "../../shared/lessons/ReviewLesson";
import { onValue, ref, set, update } from "firebase/database";
import { addCourses } from "../../../redux/coursesSlice";
import { addUsers } from "../../../redux/usersSlice";
import { addlessons } from "../../../redux/lessonsSlice";
import EditLesson from "../../shared/lessons/EditLesson";
import ShowAllAssignments from "../../shared/assignment/ShowAllAssignment";
import ReviewAssignment from "../../shared/assignment/ReviewAssignment";
import AddNewAssignment from "../../shared/assignment/AddNewAssignment";
import EditAssignment from "../../shared/assignment/EditAssignment";
import { addAssignments } from "../../../redux/assignmentsSlice";
// import CourseAssign from "../assignCourseToStudent/CourseAssign";

import GradeToStudent from "../../shared/reportCards/GradeToStudent";
import Shahname from "../../../pages/resourses/Shahname";
import Parsiban from "../../../pages/resourses/Parsiban";
import Pubmed from "../../../pages/resourses/Pubmed";
import AvestaKeyboard from "../../../pages/resourses/AvestaKeyboard";
import { addtables } from "../../../redux/tablesSlice";
import TableShow from "../../shared/resourses/showFile/TableShow";
import ShowAllGroups from "../../shared/group/ShowAllGroups";
// import ShowAllProfiles from "../profiles/ShowAllProfiles";

import { toast } from "react-toastify";
import InstructorProfile from "../../instructor/InstructorProfile";
// import AddNewGroup from "../group/AddNewGroup";
import ShowResources from "../../shared/resourses/ShowResources";
import EditCourse from "../../shared/courses/EditCourse";
import EditTable from "../../shared/resourses/tables/EditTable";
import ShowAllTables from "../../shared/resourses/tables/ShowAllTables";
import AddTable from "../../shared/resourses/tables/AddTable";
import AddNewImages from "../../shared/resourses/gallery/AddNewImages";
import ContentDrip from "../../shared/contentDrip/ContentDrip";
import AddNewVideo from "../../shared/resourses/videos/AddNewVideo";
import Categories from "../../shared/category/Categories";
import { addCategories } from "../../../redux/CategoriesSlice";
import { setImages } from "../../../redux/allImagesSlice";
import { setPdfs } from "../../../redux/allPdfsSlice";
import { setVideos } from "../../../redux/allVideosSlice";
import ShowAllNotifications from "../../shared/notifications/ShowAllNotifications";
import { addNotifications } from "../../../redux/notificationsSlice";
import PanelLayout from "../../layout/PanelLayout";
import AddNewGroup from "../../shared/group/AddNewGroup";
import ShowAllProfiles from "../../admin/profiles/ShowAllProfiles";
import ReviewUser from "../../admin/members/ReviewUser";
import ShowAllUsers from "../../admin/members/ShowAllUsers";
import AddNewMember from "../../admin/members/AddNewMember";
import CourseAssign from "../../admin/assignCourseToStudent/CourseAssign";
import ReviewGroup from "../../shared/group/ReviewGroup";
import Library from "../../shared/Library/Library";
import ALLPublicInfo from "../../admin/publicInfo/ALLPublicInfo";

const AdminPanel = () => {
  const dispatch = useDispatch();

  // if (user.profile?.role !== "admin") {
  //     console.log("not authorized")
  //     navigate("/")
  // }

  useEffect(() => {
    const allGroupInfo = ref(db, "/lact");
    return onValue(
      allGroupInfo,
      (snap) => {
        if (snap.val()) {
          const lact = [...Object.values(snap.val())];

          const courses = lact
            .map((l) => {
              if (l.courses) return Object.values(l.courses);
            })
            .flat()
            .filter((course) => course);
          const lessons = lact
            .map((l) => {
              if (l.lessons) return Object.values(l.lessons);
            })
            .flat()
            .filter((lesson) => lesson);
          const assignments = lact
            .map((l) => {
              if (l.assignments) return Object.values(l.assignments);
            })
            .flat()
            .filter((assignment) => assignment);
          const tables = lact
            .map((l) => {
              if (l.tables) return Object.values(l.tables);
            })
            .flat()
            .filter((table) => table);
          const users = lact
            .map((l) => {
              if (l.users) return Object.values(l.users);
            })
            .flat()
            .filter((user) => user);

          const withoutRef = [];
          const myf = async () => {
            const ncourses = courses.map((c) => {
              // c.info = c.courseInfo;
              // delete c.courseInfo;
              if (!c.info || !c.info.groupCode || !c.info.courseId) {
                withoutRef.push(c);
              }
              // const cRef = ref(
              //   db,
              //   `/lact/${c.info.groupCode}/courses/${c.info.courseId}`
              // );
              // return set(cRef, c);
            });

            const nlessons = lessons.map((c) => {
              // c.info = c.lessonInfo;
              // delete c.lessonInfo;
              if (!c.info || !c.info.groupCode || !c.info.lessonId) {
                withoutRef.push(c);
              }
              // const cRef = ref(
              //   db,
              //   `/lact/${c.info.groupCode}/lessons/${c.info.lessonId}`
              // );
              // return set(cRef, c);
            });
            const nassignments = assignments.map((c) => {
              // c.info = c.assignmentInfo;
              // delete c.assignmentInfo;
              if (!c.info || !c.info.groupCode || !c.info.assignmentId) {
                withoutRef.push(c);
              }
              // const cRef = ref(
              //   db,
              //   `/lact/${c.info.groupCode}/assignments/${c.info.assignmentId}`
              // );
              // return set(cRef, c);
            });

            const assp = await Promise.all(nassignments, nlessons, ncourses);
            console.log(withoutRef);
            // console.log("seccess changes", assp);
          };

          myf();
          courses && dispatch(addCourses(courses));
          lessons && dispatch(addlessons(lessons));
          assignments && dispatch(addAssignments(assignments));
          tables && dispatch(addtables(tables));
          users && dispatch(addUsers(users));
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const resRef = ref(db, "/resources");
    return onValue(
      resRef,
      (snap) => {
        let images = {};
        let videos = {};
        let pdfs = {};
        Object.values(snap.val()).forEach((resource) => {
          images = resource.image ? { ...images, ...resource.image } : images;
          videos = resource.video ? { ...videos, ...resource.video } : videos;
          pdfs = resource.pdf ? { ...pdfs, ...resource.pdf } : pdfs;

          // resource?.video &&
          //   setVideosState((prev) => ({ ...prev, ...resource?.video }));

          // resource?.pdf &&
          //   setPdfsState((prev) => ({ ...prev, ...resource?.pdf }));
        });
        images && dispatch(setImages(images));
        pdfs && dispatch(setPdfs(pdfs));
        videos && dispatch(setVideos(videos));
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const docRef = ref(db, "/categories");
    return onValue(
      docRef,
      (snap) => {
        const categories = [];
        let index = 0;
        if (snap.val()) {
          snap.forEach((childsnap) => {
            index++;
            // if (childsnap.val().profile.groupCode === user.profile.groupCode) {
            categories.push({ id: index, ...childsnap.val() });
            // }
          });
          dispatch(addCategories(categories));
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const docRef = ref(db, "/notification/manage");
    return onValue(
      docRef,
      (snap) => {
        if (snap.val()) {
          const notifications = [];
          snap.forEach((childsnap) => {
            // if (childsnap.val().profile.groupCode === user.profile.groupCode) {
            notifications.push({
              id: childsnap.key,
              ...childsnap.val(),
            });
            // }
          });
          console.log(notifications);
          notifications && dispatch(addNotifications(notifications));
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  return (
    <Routes>
      <Route path="/" element={<PanelLayout />}>
        <Route path="publicinfo" element={<ALLPublicInfo />} />
        <Route path="members" element={<ShowAllUsers />} />
        <Route path="members/viewuser/:uid" element={<ReviewUser />} />
        <Route path="addnewmember/:uid/*" element={<AddNewMember />} />
        <Route path="editmember/:uid/*" element={<AddNewMember />} />
        {/* <Route path="editmember/:uid/*" element={<EditMember />} /> */}

        <Route path="profile" element={<InstructorProfile />} />

        <Route path="students/reportcards" element={<GradeToStudent />} />
        {/* <Route path="students/assignments" element={<CourseAssign />} /> */}

        <Route path="courses" element={<ShowAllCourses />} />
        <Route path="courses/viewcourse/:courseId" element={<ReviewCourse />} />
        <Route
          path="addnewcourse/:courseId/:resourceId?/*"
          element={<EditCourse />}
        />
        <Route
          path="editcourse/:courseId/:resourceId?/*"
          element={<EditCourse />}
        />

        <Route path="contentdrip" element={<ContentDrip />} />

        <Route path="lessons" element={<ShowAllLessons />} />
        <Route path="lessons/viewlesson/:lessonId" element={<ReviewLesson />} />
        <Route
          path="addnewlesson/:lessonId/:resourceId?/*"
          element={<AddNewLesson />}
        />
        <Route
          path="editlesson/:lessonId/:resourceId?/*"
          element={<EditLesson />}
        />

        <Route path="assigncoursetostudent" element={<CourseAssign />} />

        <Route path="assignments" element={<ShowAllAssignments />} />
        <Route
          path="assignments/viewassignment/:assignmentId"
          element={<ReviewAssignment />}
        />
        <Route
          path="addnewassignment/:assignmentId/:resourceId?/*"
          element={<AddNewAssignment />}
        />
        <Route
          path="editassignment/:assignmentId/:resourceId?/*"
          element={<EditAssignment />}
        />

        <Route path="categories" element={<Categories />} />

        <Route path="tables" element={<ShowAllTables />} />
        <Route path="tables/addnewtable/:tableId" element={<AddTable />} />
        <Route path="tables/edittable/:tableId" element={<EditTable />} />
        <Route path="tables/TableShow/:tableId" element={<TableShow />} />

        <Route path="groups" element={<ShowAllGroups />} />
        <Route path="addnewgroup" element={<AddNewGroup />} />
        <Route path="editgroup/:groupId" element={<AddNewGroup />} />
        <Route path="viewgroup/:groupId" element={<ReviewGroup />} />

        <Route path="resources/*" element={<ShowResources />} />
        <Route path="resources/pdf/addnewpdf" element={<AddNewPdf />} />
        <Route path="resources/pdf/addnewpdf/:pdfId" element={<AddNewPdf />} />
        {/* <Route path="resources/video" element={<ShowAllVideos />} /> */}
        <Route path="resources/video/addnewvideo" element={<AddNewVideo />} />
        <Route
          path="resources/video/addnewvideo/:videoId"
          element={<AddNewVideo />}
        />
        {/* <Route path="resources/image" element={<ShowAllImages />} /> */}
        <Route path="resources/image/addnewimage" element={<AddNewImages />} />
        <Route
          path="resources/image/addnewimage/:imageId"
          element={<AddNewImages />}
        />

        <Route path="notifications" element={<ShowAllNotifications />} />
        <Route
          path="notifications/pushnewnotification"
          element={<AddNewNotification />}
        />
        <Route
          path="notifications/pusheditnotification/:notificationId"
          element={<AddNewNotification />}
        />
        <Route path="library" element={<Library />} />

        <Route path="tools/shahname" element={<Shahname />} />
        <Route path="tools/parsiban" element={<Parsiban />} />
        <Route path="tools/pubmed" element={<Pubmed />} />
        <Route path="tools/avestakb" element={<AvestaKeyboard />} />
        {/* <Route path='addcourse' element={<AddNewCourse />} /> */}

        <Route path="profiles" element={<ShowAllProfiles />} />
      </Route>
    </Routes>
  );
};

export default AdminPanel;
