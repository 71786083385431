import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  List,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLessonsByIds } from "../../redux/lessonsSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCourseById } from "../../redux/coursesSlice";
import EachLessonAcc from "./EachLessonAcc";
const ShowCourseContent = ({ changeComp, courseId: id }) => {
  const [expanded, setExpanded] = useState(false);
  // const location = useLocation();
  // const navigate = useNavigate();
  let { courseId } = useParams();
  courseId = courseId === undefined ? id : courseId;
  const course = useSelector((state) => getCourseById(state, courseId));
  const lessons = useSelector((state) =>
    getLessonsByIds(
      state,
      course?.assignedLessons ? Object.keys(course.assignedLessons) : []
    )
  );

  // useEffect(() => {

  //   return () => {
  //     if (
  //       location.pathname !==
  //       `/studentpanel/courses/viewcourse/${course?.info.courseId}`
  //     ) {
  //       navigate(
  //         `/studentpanel/courses/viewcourse/${course?.info.courseId}`
  //       );
  //     }
  //   };
  // }, [course?.info.courseId, location.pathname, navigate]);

  return (
    <Container
      component="div"
      disablePadding
      className="flex flex-col gap-y-3 mt-6 pb-6"
    >
      {Object.values(lessons).map((lesson, index) => (
        <EachLessonAcc
          lesson={lesson}
          index={index}
          changeComp={changeComp}
          expanded={expanded}
          setExpanded={setExpanded}
          courseId={courseId}
        />
      ))}
    </Container>
  );
};

export default ShowCourseContent;
