import {
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useSelector } from "react-redux";
import { getAssignmentsByIds } from "../../../redux/assignmentsSlice";
import EachAssignmentSidebar from "./EachAssignmentSidebar";

const SelectedListItemButton = styled(ListItemButton)(({ theme }) => ({
  // backgroundColor: "#002868",
  color: "#fff",
  paddingRight: "0px",
  paddingLeft: "0px",
  "&:hover": {
    backgroundColor: "#003a99",
  },
  "&.Mui-selected": {
    // borderLeft: "1px solid #333",
  },
}));

const SmallListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "30px", // Adjust the size as needed
  "& .MuiSvgIcon-root": {
    fontSize: "30px", // Adjust the icon size as needed
  },
}));

const EachLessonSidebar = ({ lesson, lessonIndex, setLessonIndex, index }) => {
  // const [selectedSection , setSelectedSection] = useState(f)
  const { courseId } = useParams();
  const [arrowIndex, setArrowIndex] = useState("");
  const relativeAssignmentId = lesson?.assignedAssignments
    ? Object.keys(lesson.assignedAssignments)
    : [];

  const assignments = useSelector((state) =>
    getAssignmentsByIds(state, relativeAssignmentId)
  );

  const navigate = useNavigate();
  // const assignments = useSelector((state) =>
  //   getAssignmentsByIds(
  //     state,
  //     lesson.assignedAssignments ? Object.keys(lesson.assignedAssignments) : []
  //   )
  // );

  const showLesson = () => {
    setArrowIndex(0);
    setLessonIndex(index);
    navigate(`courses/${courseId}/${lesson.id}`);
  };
  // </SelectedListItemButton>
  return (
    <div
      className={`${lessonIndex === index && "border-l-4 border-l-[#003a99]"}`}
    >
      {/* <div className="flex flex-col justify-between"> */}
      {/* <div className="flex"> */}
      {/* <Button sx={{ color: "white" }} size="small" onClick={showLesson}> */}

      {/* </Button> */}
      <SelectedListItemButton
        onClick={showLesson}
        // selected={lessonIndex === index}
      >
        <SmallListItemIcon style={{ width: "10px !important" }}>
          {arrowIndex === 0 && lessonIndex === index && (
            <ArrowRightIcon sx={{ color: "white" }} />
          )}
        </SmallListItemIcon>
        <ListItemText primary={lesson.info.title} />
      </SelectedListItemButton>
      {/* </div> */}
      {assignments &&
        assignments.map((assignment, asIndex) => (
          <EachAssignmentSidebar
            assignment={assignment}
            index={index}
            setLessonIndex={setLessonIndex}
            setArrowIndex={setArrowIndex}
            arrowIndex={arrowIndex}
            asIndex={asIndex}
            lessonIndex={lessonIndex}
          />
        ))}
      {lesson.releaseDate.date < Date.now() ||
      lesson.releaseDate.immediately ? (
        ""
      ) : (
        <LockIcon className="text-cu-red" style={{ fontSize: "20px" }} />
      )}
      {/* </div> */}
    </div>
  );
};

export default EachLessonSidebar;
