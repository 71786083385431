import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRealItem } from "../../../firebase.config";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import { useSelector } from "react-redux";
import { getUserById } from "../../../redux/usersSlice";
import ActionsMenu from "../sharedComp/ActionsMenu";
const GroupRow = ({ item }) => {
  const role = useSelector((state) => state.user.profile.role);
  const navigate = useNavigate();
  const goToEditGroup = () => {
    // dispatch(getSelectedGroup({ item }));
    navigate(`/adminpanel/editgroup/${item.id}`);
  };

  const supervisor = useSelector((state) =>
    getUserById(state, item?.supervisor)
  );

  const deleteGroup = async () => {
    if (!item.groupId) throw new Error("id is not exists");
    if (window.confirm("are you sure to remove group")) {
      const realPath = `/groups/${item.id}`;
      await deleteRealItem(realPath);
      toast.success("successfully delete group");
    }
  };

  const viewGroup = () => {
    navigate(`/${role}panel/viewgroup/${item.id}`);
  };

  return (
    <tr className="border-b h-12 flex items-center relative text-center">
      <ActionsMenu
        onView={viewGroup}
        onEdit={goToEditGroup}
        onDelete={deleteGroup}
      />

      {/* <p className="absolute left-2 top-0 text-slate-600 me-3 mt-3 ">
        <span>{profile.role}: </span>
        <span className="text-xs">{profile.uid}</span>
      </p> */}

      <td className="w-1/5 flex items-center justify-center">
        <Avatar
          className="rounded-full border w-14 h-14"
          sx={{ bgcolor: `${item.color}` }}
        >
          {item.id}
        </Avatar>
        {/* <td className="w-1/5">
          <p>{item.code}</p>
        </td> */}
      </td>
      <td className="w-1/5">
        <p>{item.englishName}</p>
      </td>
      <td className="w-1/5">
        <p>{item.persianName}</p>
      </td>
      <td className="w-1/5 flex items-center">
        <Avatar
          className="rounded-full border w-14 h-14 object-contain"
          src={supervisor?.profile?.photoUrl?.url}
          alt={supervisor?.profile?.name}
        />
        <p className="ms-4">
          {supervisor?.profile?.name} {supervisor?.profile?.family}
        </p>
      </td>
    </tr>
  );
};

export default GroupRow;
