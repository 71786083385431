import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createALesson } from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addLessonInfo,
  createEmptyLesson,
  getAllLessons,
  getLessonById,
  removeNotPublishedLesson,
} from "../../../redux/lessonsSlice";
import AssignmentAssign from "./assignmentAssign/AssignmentAssign";
import ShowAddedResources from "../sharedComp/addResource/ShowAddedResources";
import GroupCodeSection from "../sharedComp/GroupCodeSection";
import { Button } from "@mui/material";
import TextAreaComponent from "../sharedComp/TextAreaComponent";

const AddNewLesson = () => {
  const user = useSelector((state) => state.user);
  const { lessonId } = useParams();
  const lessons = useSelector(getAllLessons);
  let lesson = useSelector((state) => getLessonById(state, lessonId));
  const [groupCode, setGroupCode] = useState(
    lesson?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : "")
  );

  const newLessonInitialState = {
    info: {
      lessonId,
      // visibility: "Public",
      title: "",
      explain: "",
      editAt: "",
      createAt: "",
      course: "",
      lessonCreator: "",
      status: "draft",
      titleDirection: "rtl",
      creatorId: user.profile.uid,
      groupCode,
      name: "",
      nameDirection: "",
      // pdfTitle: "",
    },
    releaseDate: { immediately: true, date: Date.now() },
    resources: "",
    assignedCourses: "",
  };

  const [inputDirection, setInputDirection] = useState(
    lesson?.info?.titleDirection || "rtl"
  );
  const [nameDirection, setNameDirection] = useState(
    lesson?.info?.nameDirection || "rtl"
  );
  const [name, setName] = useState(lesson?.info?.name);
  const navigate = useNavigate();
  const creatorEmail = useSelector((state) => state.user.profile.email);
  const dispatch = useDispatch();
  const [title, setTitle] = useState(lesson?.info?.title || "");
  const [explain, setExplain] = useState(lesson?.info?.explain || "");
  if (!lesson) {
    lesson = newLessonInitialState;
  }

  useEffect(() => {
    if (lessonId) {
      const newLessonInitialState = {
        info: {
          lessonId,
          title: "",
          explain: "",
          editAt: "",
          createAt: Date.now(),
          course: "",
          creatorId: user.profile.uid,
          lessonCreator: "",
          status: "temp",
          rowId: lessons.length + 1 || 0,
          groupCode,
          name,
          nameDirection,
        },
        resources: "",
        assignedCourses: "",
        releaseDate: {
          date: Date.now(),
          immediately: true,
        },
      };
      dispatch(createEmptyLesson({ id: lessonId, ...newLessonInitialState }));
    }

    return () => {
      if (lesson.info.status !== "temp") return;
      dispatch(removeNotPublishedLesson(lesson.info.lessonId));
    };
  }, [lessonId, dispatch, groupCode]);

  const publishLesson = async () => {
    if (title.length < 3) {
      toast.error("title must have 4 letter or more");
      return;
    }
    const info = {
      lessonId,
      // visibility,
      title,
      explain,
      titleDirection: inputDirection,
      editAt: "",
      createAt: Date.now(),
      lessonCreator: creatorEmail,
      status: "published",
      rowId: lesson.info.rowId,
      creatorId: user.profile.uid,
      groupCode,
      name,
      nameDirection,
    };
    const lessonToSend = {
      ...lesson,
      info,
    };
    dispatch(addLessonInfo((lesson, lessonId)));
    await createALesson(lessonToSend);
    toast.success("successfully add new lesson");
    navigate(`/${user.profile.role}panel/lessons`);
  };

  return (
    <div className="flex ">
      <div className="w-9/12 mx-5 mt-5">
        <div className="mb-3 flex justify-between">
          <h2>Add New Lesson</h2>
          <h2>{lessonId}</h2>
        </div>
        <TextAreaComponent
          props={{
            explain,
            setTitle,
            title,
            inputDirection,
            setInputDirection,
            setExplain,
            groupCode,
            name,
            nameDirection,
            setName,
            setNameDirection,
          }}
        />

        <ShowAddedResources
          section={lesson}
          sectionName={"lessons"}
          sectionId={lessonId}
          status={lesson?.info?.status}
          groupCode={lesson?.info?.groupCode}
        />
      </div>

      <div className="w-2/12 mt-12 flex flex-col gap-y-5">
        {user.profile.role === "admin" && (
          <GroupCodeSection groupCode={groupCode} setGroupCode={setGroupCode} />
        )}

        <AssignmentAssign lesson={lesson} />

        <Button
          variant="contained"
          className="flex w-24 self-end"
          sx={{ marginTop: "20px" }}
          onClick={publishLesson}
        >
          Publish
        </Button>
      </div>
    </div>
  );
};

export default AddNewLesson;
