import React, { useContext, useEffect, useState } from "react";
import { db } from "../../../firebase.config";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AddNewCourse from "../../shared/courses/AddNewCourse";
import AddNewLesson from "../../shared/lessons/AddNewLesson";
import ShowAllLessons from "../../shared/lessons/ShowAllLessons";
import AddNewVideo from "../../shared/resourses/videos/AddNewVideo";
import ReviewCourse from "../../shared/courses/ReviewCourse";
import ReviewLesson from "../../shared/lessons/ReviewLesson";
import { onValue, ref } from "firebase/database";
import { addCourses } from "../../../redux/coursesSlice";
import { addlessons } from "../../../redux/lessonsSlice";
import EditLesson from "../../shared/lessons/EditLesson";
import ShowAllAssignments from "../../shared/assignment/ShowAllAssignment";
import ReviewAssignment from "../../shared/assignment/ReviewAssignment";
import AddNewAssignment from "../../shared/assignment/AddNewAssignment";
// import { addAssignments } from "../../../redux/assignmentsSlice";
import GradeToStudent from "../../shared/reportCards/GradeToStudent";
import ContentDrip from "../../shared/contentDrip/ContentDrip";
import Shahname from "../../../pages/resourses/Shahname";
import Parsiban from "../../../pages/resourses/Parsiban";
import Pubmed from "../../../pages/resourses/Pubmed";
import AvestaKeyboard from "../../../pages/resourses/AvestaKeyboard";
import AddNewImages from "../../shared/resourses/gallery/AddNewImages";
import EditTable from "../../shared/resourses/tables/EditTable";
import ShowResources from "../../shared/resourses/ShowResources";

import EditCourse from "../../shared/courses/EditCourse";
import { addtables } from "../../../redux/tablesSlice";
import { addUsers } from "../../../redux/usersSlice";
import { toast } from "react-toastify";
import ShowAllCourses from "../../shared/courses/ShowAllCourses";
import AddNewPdf from "../../shared/resourses/library/AddNewPdf";
import ShowAllProfiles from "../../admin/profiles/ShowAllProfiles";
import TableShow from "../../shared/resourses/showFile/TableShow";
import ShowAllTables from "../../shared/resourses/tables/ShowAllTables";
import EditAssignment from "../../shared/assignment/EditAssignment";
import AddTable from "../../shared/resourses/tables/AddTable";
import { setImages } from "../../../redux/allImagesSlice";
import { setPdfs } from "../../../redux/allPdfsSlice";
import { setVideos } from "../../../redux/allVideosSlice";
import PanelLayout from "../PanelLayout";
import InstructorProfile from "../../instructor/InstructorProfile";
import { addAssignments } from "../../../redux/assignmentsSlice";
import { addCategories } from "../../../redux/CategoriesSlice";
import ShowAllGroups from "../../shared/group/ShowAllGroups";
import ReviewGroup from "../../shared/group/ReviewGroup";
import Library from "../../shared/Library/Library";
import Categories from "../../shared/category/Categories";

const InstructorPanel = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imagesState, setImagesState] = useState({});
  const [videosState, setVideosState] = useState({});
  const [pdfsState, setPdfsState] = useState({});
  // if (user.profile?.role !== "admin") {
  //     console.log("not authorized")
  //     navigate("/")
  // }

  useEffect(() => {
    if (!user.profile.groupCode) return;
    const coursesRef = ref(db, `/lact/${user.profile.groupCode}`);
    return onValue(
      coursesRef,
      (snap) => {
        if (snap.val()) {
          const courses = snap.val().courses
            ? Object.values(snap.val().courses)
            : [];
          const assignments = snap.val().assignments
            ? Object.values(snap.val().assignments)
            : [];
          const lessons = snap.val().lessons
            ? Object.values(snap.val().lessons)
            : [];
          const tables = snap.val().tables
            ? Object.values(snap.val().tables)
            : [];
          const users = snap.val().users ? Object.values(snap.val().users) : [];

          dispatch(addCourses(courses));
          dispatch(addAssignments(assignments));
          dispatch(addlessons(lessons));
          dispatch(addtables(tables));
          dispatch(addUsers(users));

          // snap.val().resources?.image &&
          //   setImagesState((prev) => ({
          //     ...prev,
          //     ...snap.val().resources?.image,
          //   }));

          // snap.val().resources?.video &&
          //   setVideosState((prev) => ({
          //     ...prev,
          //     ...snap.val().resources?.video,
          //   }));

          // snap.val().resources?.pdf &&
          //   setPdfsState((prev) => ({ ...prev, ...snap.val().resources?.pdf }));
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (!user.profile.groupCode) return;
    const resRef = ref(db, `/resources/${user.profile.groupCode}`);
    return onValue(resRef, (snap) => {
      if (snap.val()) {
        const resource = snap.val();
        resource?.image && dispatch(setImages(resource.image));
        resource?.pdf && dispatch(setPdfs(resource.pdf));
        resource?.video && dispatch(setVideos(resource.video));
      }
    });
  }, []);

  useEffect(() => {
    const docRef = ref(db, "/categories");
    return onValue(
      docRef,
      (snap) => {
        const categories = [];
        let index = 0;
        if (snap.val()) {
          snap.forEach((childsnap) => {
            index++;
            // if (childsnap.val().profile.groupCode === user.profile.groupCode) {
            categories.push({ id: index, ...childsnap.val() });
            // }
          });
          dispatch(addCategories(categories));
        }
      },
      (error) => {
        toast.error(error.message);
        console.log(error);
      }
    );
  }, []);
  // useEffect(() => {
  //   const usersRef = ref(db, `/lact/${user.profile.groupCode}/users`);
  //   return onValue(
  //     usersRef,
  //     (snap) => {
  //       const users = [];
  //       if (snap.val()) {
  //         snap.forEach((childsnap) => {
  //           if (childsnap.val().profile.groupCode === user.profile.groupCode) {
  //             users.push({ id: childsnap.key, ...childsnap.val() });
  //           }
  //         });
  //         dispatch(addUsers(users));
  //       }
  //     },
  //     (error) => {
  //       toast.error(error.message);
  //       console.log(error);
  //     }
  //   );
  // }, []);

  return (
    <Routes>
      <Route path="/" element={<PanelLayout />}>
        {/* <Route path="members" element={<ShowAllUsers />} />
        <Route path="members/viewuser/:uid" element={<ReviewUser />} />
        <Route path="addnewmember/:uid/*" element={<AddNewMember />} />
        <Route path="editmember/:uid/*" element={<AddNewMember />} /> */}
        {/* <Route path="editmember/:uid/*" element={<EditMember />} /> */}

        <Route path="profile" element={<InstructorProfile />} />

        <Route path="groups" element={<ShowAllGroups />} />
        <Route path="viewgroup/:groupId" element={<ReviewGroup />} />

        <Route path="students/reportcards" element={<GradeToStudent />} />

        <Route path="courses" element={<ShowAllCourses />} />
        <Route path="courses/viewcourse/:courseId" element={<ReviewCourse />} />
        <Route
          path="addnewcourse/:courseId/:resourceId?/*"
          element={<AddNewCourse />}
        />
        <Route
          path="editcourse/:courseId/:resourceId?/*"
          element={<EditCourse />}
        />

        <Route path="contentdrip" element={<ContentDrip />} />

        <Route path="lessons" element={<ShowAllLessons />} />
        <Route path="lessons/viewlesson/:lessonId" element={<ReviewLesson />} />
        <Route
          path="addnewlesson/:lessonId/:resourceId?/*"
          element={<AddNewLesson />}
        />
        <Route
          path="editlesson/:lessonId/:resourceId?/*"
          element={<EditLesson />}
        />

        {/* <Route path="assigncoursetostudent" element={<CourseAssign />} /> */}

        <Route path="assignments" element={<ShowAllAssignments />} />
        <Route
          path="assignments/viewassignment/:assignmentId"
          element={<ReviewAssignment />}
        />
        <Route
          path="addnewassignment/:assignmentId/:resourceId?/*"
          element={<AddNewAssignment />}
        />
        <Route
          path="editassignment/:assignmentId/:resourceId?/*"
          element={<EditAssignment />}
        />
        <Route path="tables" element={<ShowAllTables />} />
        <Route path="tables/addnewtable/:tableId" element={<AddTable />} />
        <Route path="tables/edittable/:tableId" element={<EditTable />} />
        <Route path="tables/viewtable/:tableId" element={<TableShow />} />

        <Route path="resources/*" element={<ShowResources />} />
        <Route path="resources/pdf/addnewpdf" element={<AddNewPdf />} />
        <Route path="resources/pdf/addnewpdf/:pdfId" element={<AddNewPdf />} />
        {/* <Route path="resources/video" element={<ShowAllVideos />} /> */}
        <Route path="resources/video/addnewvideo" element={<AddNewVideo />} />
        <Route
          path="resources/video/addnewvideo/:videoId"
          element={<AddNewVideo />}
        />
        {/* <Route path="resources/image" element={<ShowAllImages />} /> */}
        <Route path="resources/image/addnewimage" element={<AddNewImages />} />
        <Route
          path="resources/image/addnewimage/:imageId"
          element={<AddNewImages />}
        />
        <Route path="categories" element={<Categories />} />

        {/* <Route path="notifications" element={<PushNotification />} />
        <Route
          path="notifications/sentnotifications"
          element={<CheckSendNotifications />}
        /> */}
        <Route path="library" element={<Library />} />
        <Route path="tools/shahname" element={<Shahname />} />
        <Route path="tools/parsiban" element={<Parsiban />} />
        <Route path="tools/pubmed" element={<Pubmed />} />
        <Route path="tools/avestakb" element={<AvestaKeyboard />} />
        {/* <Route path='addcourse' element={<AddNewCourse />} /> */}

        <Route path="profiles" element={<ShowAllProfiles />} />
      </Route>
    </Routes>
  );
};

export default InstructorPanel;
