import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    updatePassword,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { deleteObject, getBlob, getDownloadURL, getMetadata, getStorage, listAll, ref as storageRef, updateMetadata, uploadBytesResumable } from "firebase/storage";

import { getDatabase, onValue, ref, update, serverTimestamp, set, remove, get, equalTo, startAt, limitToLast, child, endAt, endBefore } from "firebase/database";
import { toast } from "react-toastify";


const firebaseConfig = {
    apiKey: "AIzaSyA_q-ywKAkZVXg5HPITxGpU-QQiaOnJrhA",
    authDomain: "cyrus-university-website.firebaseapp.com",
    projectId: "cyrus-university-website",
    storageBucket: "cyrus-university-website.appspot.com",
    messagingSenderId: "777209034475",
    appId: "1:777209034475:web:4624afc4848c141da9f0ae",
    measurementId: "G-SPZ0ZB4QFH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);

export const auth = getAuth();
export const getUser = () => {
    return auth.currentUser;
};


export const userAuthChange = (cb) => {
    return onAuthStateChanged(auth, cb);
};

export const getUrlBlob = async (path, signal, onProgress) => {
    try {
        const storage = getStorage();
        const fileRef = storageRef(storage, path);
        const downloadURL = await getDownloadURL(fileRef);

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', downloadURL, true);

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                onProgress(progress);
            }
        };

        xhr.onload = () => {
            const url = URL.createObjectURL(xhr.response);
            return url;
        };

        xhr.onerror = (error) => {
            if (signal.aborted) {
                console.log('Request canceled');
            } else {
                console.log(error);
            }
        };

        xhr.send();

        return new Promise((resolve, reject) => {
            xhr.onload = () => resolve(URL.createObjectURL(xhr.response));
            xhr.onerror = () => reject(xhr.statusText);
            signal.addEventListener('abort', () => {
                xhr.abort();
                reject('Request canceled');
            });
        });
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Request canceled');
        } else {
            console.log(error);
        }
    }
};

export const createACourse = async (courseData) => {
    if (!courseData.info.courseId || !courseData.info.groupCode) {
        throw new Error("course id is not exists")
    }
    const courseRef = ref(db, `/lact/${courseData.info.groupCode}/courses/${courseData.info.courseId}`)
    try {
        // const ifCourseExists = (await get(courseRef)).exists()
        // if (ifCourseExists) {
        //     throw new Error("course already exists with the same id!!!")
        // }
        await update(courseRef, courseData)
    } catch (error) {
        throw new Error(error)
    }
}

export const sendImagesToDb = async (imageFile, groupCode) => {
    const docRef = ref(db, `/resources/${groupCode}/image/${imageFile.info.imageId}`)
    try {
        await update(docRef, imageFile)
    } catch (error) {
        console.log(error)
    }
}


export const createALesson = async (lesson) => {
    if (!lesson.info.lessonId || !lesson.info.groupCode) {
        throw new Error("lesson id is not exists")
    }
    const lessonInfoRef = ref(db, `/lact/${lesson.info.groupCode}/lessons/${lesson.info.lessonId}`)
    try {
        await update(lessonInfoRef, lesson)
    } catch (error) {
        throw new Error(error)
    }

    const aaa = ref(db, `/lact/${lesson.info.groupCode}/lessons/${lesson.info.lessonId}/releaseDate`)
    try {
        await update(aaa, { immediately: true, date: Date.now() },
        )
    } catch (error) {
        throw new Error(error)
    }
}
export const createAAssignment = async (assignment) => {
    if (!assignment.info.assignmentId || !assignment.info.groupCode) {
        throw new Error("assignment id is not exists")
    }
    const assignmentInfoRef = ref(db, `/lact/${assignment.info.groupCode}/assignments/${assignment.info.assignmentId}`)
    try {
        await update(assignmentInfoRef, assignment)
    } catch (error) {
        throw new Error(error)
    }
    const aaa = ref(db, `/lact/${assignment.info.groupCode}/assignments/${assignment.info.assignmentId}/releaseDate`)
    try {
        await update(aaa, { immediately: true, date: Date.now() },
        )
    } catch (error) {
        throw new Error(error)
    }
}


export const sendGrade = async ({ grade, uid, courseId, gradeGiverId, groupCode }) => {
    if (!grade || !uid || !courseId || !groupCode) {
        throw new Error("one of field is empty")
    }
    const studentRef = ref(db, `/lact/${groupCode}/users/${uid}/reportCards/${courseId}`)
    const courseRef = ref(db, `/lact/${groupCode}/courses/${courseId}/reportCards/${uid}`)

    const gradeObj = { grade, status: grade >= 60 ? "passed" : "failed", creatorId: gradeGiverId }
    try {
        await update(studentRef, gradeObj)
        await update(courseRef, gradeObj)
        return "successfully update grade"
    } catch (error) {
        throw new Error(error)
    }


}

export const checkIfPathExists = async (path) => {
    const docRef = ref(db, path)
    try {
        const dataSnapShot = await get(docRef)
        return dataSnapShot.exists()
    } catch (error) {
        console.log("there was an error in check if data exists", error)
    }
}


export const assignLessonToAssignment = async ({ list, lesson, assignedAssignments }) => {
    if (!lesson.info.lessonId || !lesson.info.groupCode) {
        throw new Error("lesson id doesn't exists")
    }

    const assignmentIdInLesson = ref(db, `/lact/${lesson.info.groupCode}/lessons/${lesson.info.lessonId}/assignedAssignments`)

    try {
        const listObj = list.reduce((acc, item) => {
            if (!item) {
                throw new Error("assignment id not found")
            }
            acc[item] = true;
            return acc;
        }, {});
        await set(assignmentIdInLesson, listObj)

        const onlyInAssignedAssignments = assignedAssignments.filter(assignment =>
            !list.some(item => item === assignment)
        );

        const onlyInList = list.filter(item =>
            !assignedAssignments.some(assignment => assignment === item)
        );
        const removePromise = onlyInAssignedAssignments.map((assignmentId) => {
            const assignmentidInLesson = ref(db, `/lact/${lesson.info.groupCode}/assignments/${assignmentId}/assignedLessons/${lesson.info.lessonId}`)
            return remove(assignmentidInLesson)
        })

        const addPromise = onlyInList.map((assignmentId) => {
            const assignmentidInLesson = ref(db, `/lact/${lesson.info.groupCode}/assignments/${assignmentId}/assignedLessons`)
            return update(assignmentidInLesson, { [lesson.info.lessonId]: true })
        })

        await Promise.all(removePromise, addPromise)

        return { message: "changes successfully done" }

    } catch (error) {
        throw new Error(error)
    }
}
export const updateContentDripToDb = async (assignmentList, lessonList, groupCode) => {
    // if (assignmentList.length) {
    if (!groupCode) throw new Error("unspecified group code")

    const asPromise = assignmentList?.map((as) => {
        if (!as.id) {
            throw new Error(as.id + "assignment id not found")
        }
        const aRef = ref(db, `/lact/${groupCode}/assignments/${as.id}/releaseDate`)
        return update(aRef, { date: as.date, immediately: as.immediately })
    })
    const lsPromise = lessonList?.map((ls) => {
        if (!ls.id) {
            throw new Error(ls.id + "lesson id not found")
        }
        const aRef = ref(db, `/lact/${groupCode}/lessons/${ls.id}/releaseDate`)
        return update(aRef, { date: ls.date, immediately: ls.immediately })
    })
    try {
        await Promise.all(asPromise, lsPromise)
        return "successfully update release date"
    } catch (error) {
        throw new Error(error)
    }
    // }
}

export const assignCourseToLesson = async ({ list, courseId, assignedLessons, groupCode }) => {
    if (!courseId || !groupCode) {
        throw new Error("course id doesn't exists")
    }
    console.log(list, courseId, assignedLessons)
    const lessonidInCourse = ref(db, `/lact/${groupCode}/courses/${courseId}/assignedLessons`)

    try {
        const listObj = list.reduce((acc, item) => {
            if (!item) {
                throw new Error(item + "lesson id not found")
            }
            acc[item] = true;
            return acc;
        }, {});
        await set(lessonidInCourse, listObj)

        const onlyInAssignedLessons = assignedLessons.filter(lesson =>
            !list.some(item => item === lesson)
        );

        const onlyInList = list.filter(item =>
            !assignedLessons.some(lesson => lesson === item)
        );

        const removePromise = onlyInAssignedLessons.map((lessonId) => {

            const courseidInLesson = ref(db, `/lact/${groupCode}/lessons/${lessonId}/assignedCourses/${courseId}`)
            return remove(courseidInLesson)
        })

        const addPromise = onlyInList.map((lessonId) => {
            const courseidInLesson = ref(db, `/lact/${groupCode}/lessons/${lessonId}/assignedCourses`)
            return update(courseidInLesson, { [courseId]: groupCode })
        })

        await Promise.all(removePromise, addPromise)

        return { message: "changes successfully done" }



    } catch (error) {
        throw new Error(error)
    }
}


export const saveAboutMe = async ({ uid, aboutMe, groupCode }) => {
    if (!uid || !groupCode) throw new Error("id does not exists")
    if (uid !== auth.currentUser.uid) {
        throw new Error("you are not authorized for this action")
    }
    const docRef = ref(db, `/lact/${groupCode}/users/${uid}/importedInfo/aboutMe`)
    try {
        await set(docRef, aboutMe)
    } catch (error) {
        console.log(error)
    }
}

export const assignStudentToCourse = async ({ uid, role, groupCode,
    list,
    assignedCourses }) => {
    if (!uid || !role || !groupCode) {
        throw new Error("uid or role doesn't exists")
    }

    let updatePart = role === "instructor" ? "assignedInstructor" : "assignedStudents"
    // if (role === "student") {
    //     updatePart = "assignedStudents"
    // } else if (role === "instructor") {
    //     updatePart = "assignedInstructor"
    // } else {

    // }

    const courseIdInUser = ref(db, `lact/${groupCode}/users/${uid}/assignedCourses`)

    try {
        const listObj = list.reduce((acc, lcid) => {
            if (!lcid) {
                throw new Error("course id doesnt exists")
            }
            acc[lcid] = groupCode;
            return acc;
        }, {});

        await set(courseIdInUser, listObj)


        const onlyInAssignedCourses = assignedCourses.filter(course =>
            !list.some(item => item === course)
        );

        const onlyInList = list.filter(item =>
            !assignedCourses.some(course => course === item)
        );

        const removePromise = onlyInAssignedCourses.map((courseId) => {
            const userIdInCourse = ref(db, `/lact/${groupCode}/courses/${courseId}/${updatePart}/${uid}`)
            return remove(userIdInCourse)
        })


        const addPromise = onlyInList.map((courseId) => {
            const userIdInCourse = ref(db, `/lact/${groupCode}/courses/${courseId}/${updatePart}`)
            return update(userIdInCourse, { [uid]: groupCode })
        })

        await Promise.all(removePromise, addPromise)

        return listObj


    } catch (error) {
        throw new Error(error)
    }
}

export const checkIfStoragePathExists = async (path) => {
    const storage = getStorage();
    const fileRef = storageRef(storage, path);

    try {
        const res = await getDownloadURL(fileRef);
        console.log(res)
        return true; // File exists
    } catch (error) {
        if (error.code === 'storage/object-not-found') {
            return false; // File does not exist
        } else {
            throw error; // Handle other errors
        }
    }

}



// export const getDocumentUser = async (uid) => {
//     const docRef = ref(db, `/lact/${groupCode}/users/${uid}`);

//     const snapshot = await get(docRef)
//     return snapshot.val()
// };
export const getUserInfo = async (uid, groupCode) => {
    const docRef = ref(db, `/lact/${groupCode}/users/${uid}`);
    const snapshot = await get(docRef)
    return snapshot.val()
};


export const getUsersQuery = () => {
    const usersRef = ref(db, "users");
    return usersRef
}






export const signInWithEmail = async (email, password) => {
    if (!auth || !email || !password) return
    try {
        const userCredetial = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const idTokenResult = await userCredetial.user.getIdTokenResult();
        const groupCode = idTokenResult.claims.groupCode;
        if (!groupCode) throw new Error("there is a problem with claim")

        const userProfileRef = ref(db, `/lact/${groupCode}/users/${userCredetial.user.uid}/profile`)
        await update(userProfileRef, { lastLogin: serverTimestamp() })
        const userInfo = await get(userProfileRef)
        return userInfo.val()
    } catch (error) {
        throw new Error(error);
    }
};




export const changePassword = async ({ email, password, newPassword }) => {
    if (!email || !password || !newPassword) throw new Error("one field is missed")
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const idTokenResult = await userCredential.user.getIdTokenResult();
        const groupCode = idTokenResult.claims.groupCode;
        if (!groupCode) throw new Error("there is a problem with group code")
        await updatePassword(userCredential.user, newPassword)

        const docRef = ref(db, `/lact/${groupCode}/users/${userCredential.user.uid}/profile`)
        await update(docRef, { mustChangePassword: false })
        // const userInfo = await getDocumentUser(userCredential.user.uid)
        // return userInfo
        return true
    } catch (error) {
        throw new Error(error)
    }
}

export const signOutUser = async () => {
    await signOut(auth);
};


// export const uploadPicture = async ({ uid, url ,  groupCode}) => {
//     if (!uid|| !groupCode) throw new Error("an error take place in update picture")
//     try {
//         const docRef = ref(db, `/lact/${groupCode}/users/${uid}/profile`)
//         // const photoUrlRef = child(docRef, "photoUrl")
//         await update(docRef, { photoUrl: url })
//         return { message: "successfully upload image" }
//     } catch (error) {
//         throw new Error(error)
//     }
// }




export const updateRegisteryForm = async ({ uid, pdf, groupCode }) => {
    if (!uid || !groupCode || !pdf.name) throw new Error("an error take place in update forms")

    try {
        const docRef = ref(db, `/lact/${groupCode}/users/${uid}/documents/registeryForm/${pdf.name}`)
        const success = await update(docRef, pdf)
        return { message: "successfully upload image", success }
    } catch (error) {
        throw new Error(error)
    }
}
export const updatePdfRealPath = async ({ pdf, path }) => {
    if (!path) throw new Error("an error take place in update forms")

    try {
        const docRef = ref(db, `${path}/${pdf.name}`)
        await update(docRef, pdf)
    } catch (error) {
        throw new Error(error)
    }
}
export const removeSinleRealPath = async (path) => {
    const docRef = ref(db, path)
    try {
        await remove(docRef)
    } catch (error) {
        throw new Error(error)
    }
}

export const updateAddress = async ({ uid, addressInfo, groupCode }) => {
    if (!uid) throw new Error("an error take place in update forms")
    const docRef = ref(db, `/lact/${groupCode}/users/${uid}`)
    await update(docRef, { address: addressInfo })
}

export const changeRowIdInDb = async ({ lessonId, rowId, groupCode }) => {
    if (!lessonId || !rowId) {
        throw new Error("ids are not found")
    }
    const docRef = ref(db, `/lact/${groupCode}/lessons/${lessonId}/info`)
    try {
        await update(docRef, { rowId })
    } catch (error) {
        console.log(error)
    }
}


export const postPdfUserEssay = async (pdf) => {
    if (!pdf.info.pdfId) throw new Error("pdf id not found")
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    const groupCode = idTokenResult.claims.groupCode;
    if (!groupCode) throw new Error("there is problem in user claim")
    const docRef = ref(db, `/lact/${groupCode}/users/${auth.currentUser.uid}/importedInfo/essays/${pdf.info.pdfId}`)
    try {
        await update(docRef, pdf)
        return ({ message: "successfully send pdf to DB" })
    } catch (error) {
        console.log(error)
        throw new Error(`${error} , error in send pdf`)
    }
}
export const postVideoDataToDb = async (video) => {
    if (!video.info.videoId || !video.info.groupCode) throw new Error("video id not found")

    const docRef = ref(db, `/resources/${video.info.groupCode}/video/${video.info.videoId}`)
    try {
        await update(docRef, video)
        return ({ message: "successfully send video to DB" })
    } catch (error) {
        return new Error(`${error} , error in send video`)
    }
}

export const postPdfDataToDb = async (pdf) => {
    if (!pdf.info.pdfId || !pdf.info.groupCode) throw new Error("pdf id not found")

    const docRef = ref(db, `/resources/${pdf.info.groupCode}/pdf/${pdf.info.pdfId}`)
    try {
        await update(docRef, pdf)
        return ({ message: "successfully send pdf to DB" })
    } catch (error) {
        console.log(error)
        throw new Error(`${error} , error in send pdf`)
    }
}

export const uploadGroup = async ({
    color,
    persianName,
    englishName,
    supervisor,
    id,
    catalog
}) => {
    if (!id) throw new Error("group id not found")
    const docRef = ref(db, `/groups/${id}`)
    try {
        await set(docRef, {
            color,
            id,
            persianName,
            englishName,
            supervisor,
            catalog
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const postImageDataToDb = async (image) => {
    if (!image.info.imageId || !image.info.groupCode) throw new Error("image id not found")

    const docRef = ref(db, `/resources/${image.info.groupCode}/image/${image.info.imageId}`)
    try {
        await update(docRef, image)
        return ({ message: "successfully send image to DB" })
    } catch (error) {
        return new Error(`${error} , error in send image`)
    }
}

export const addResourceToLCADB = async ({ resourceData, sectionId, sectionName, groupCode }) => {
    if (!sectionName || !sectionId || !resourceData.resourceId || !groupCode) {
        throw new Error("one of element is missing")
    }
    const docRef = ref(db, `/lact/${groupCode}/${sectionName}/${sectionId}/resources/${resourceData.resourceId}`)
    try {
        await set(docRef, resourceData)
        resourceData.type && toast.success(`${resourceData.type} set for resource number ${resourceData.resourceId} for ${sectionName}`)
    } catch (error) {
        throw new Error(error)
    }
}

// export const uploadCoursePic = async ({ uid, url }) => {
//     if (!uid) throw new Error("an error take place in update picture")
//     try {
//         const docRef = ref(db, "/courses/" + uid + "/files")
//         // const photoUrlRef = child(docRef, "photoUrl")
//         await update(docRef, { courseImage: url })
//         return { message: "successfully upload image" }
//     } catch (error) {
//         throw new Error(error)
//     }
// }


const addIdByOne = async (section) => {
    let sectionId;
    const updateRef = ref(db, `/managementInfo/idCounter`);
    const getRef = ref(db, `/managementInfo/idCounter/${section}`);
    try {
        let snap = await get(getRef);
        if (!snap.exists()) {
            await update(updateRef, { [section]: 1 });
            sectionId = section + "_1";
        } else {
            let lastNum = await snap.val();
            lastNum += 1;
            await update(updateRef, { [section]: lastNum });
            sectionId = section + "_" + lastNum;
        }
        return sectionId
    } catch (error) {
        toast.error(error.message, "problem assign course id");
    }
};


export const sendNotificationToDb = async ({ title, text, selectedUsers, notificationAlert, groupCode,
    selectedCourses,
    selectedRole,
    customizeSelection,
    creatorId, inputDirection,
    textDirection,
}) => {
    if (!selectedUsers.length) throw new Error("please choose user to send notification")
    const notificationId = await addIdByOne("notification")
    if (!notificationId) throw new Error("notification ID not found")
    const promisedNotification = selectedUsers.map((selectedUser) => {
        if (!selectedUser.profile.uid) throw new Error("user id not found")
        // const docRef = ref(db, `/notifications/content/${selectedUser.profile.uid}`)
        const docRef = ref(db, `/notification/users/${selectedUser.profile.uid}/${notificationId}`)
        return update(docRef, {
            title, text, createAt: serverTimestamp(), alert: notificationAlert, notificationId, seen: false, inputDirection,
            textDirection,
        })
    })
    const docRef = ref(db, `/notification/manage/${notificationId}`)

    try {
        await update(docRef, {
            users: selectedUsers.map(user => user.profile.uid), info: {
                notificationId, title, text, createAt: serverTimestamp(), alert: notificationAlert, creatorId, inputDirection,
                textDirection
            }, sendList: { selectedCourses, selectedRole, groupCode, customizeSelection }
        })
        await Promise.all(promisedNotification)
    } catch (error) {
        toast.error(error.message)
        console.log(error)
    }
}

export const removeNotificationFromDb = async (notification) => {
    const manageNoteRef = ref(db, `/notification/manage/${notification.info.notificationId}`)
    const notiUserPromise = notification.users.map((userId) => {
        const docRef = ref(db, `/notification/users/${userId}/${notification.info.notificationId}`)
        if (!userId) throw new Error("user id not found")
        return remove(docRef)
    })
    try {
        await remove(manageNoteRef)
        await notiUserPromise
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const editNotificationInDb = async ({ title, text, selectedUsers, notificationAlert, groupCode,
    selectedCourses,
    selectedRole, notificationId, customizeSelection, creatorId, inputDirection,
    textDirection, }) => {
    if (!selectedUsers.length) throw new Error("please choose user to send notification")
    if (!notificationId) throw new Error("notification ID not found")
    const userOldRef = ref(db, `/notification/manage/${notificationId}/users`)
    const userOldSnap = await get(userOldRef)
    const usersToDelete = userOldSnap.val().filter((oldUser) => !selectedUsers.map(user => user.profile.uid).includes(oldUser))
    const removeUsersPromise = usersToDelete.map((user) => {
        const docRef = ref(db, `/notification/users/${user}/${notificationId}`)
        remove(docRef)
    })
    await Promise.all(removeUsersPromise)

    const sendUserPromise = selectedUsers.map((selectedUser) => {
        if (!selectedUser.profile.uid) throw new Error("user id not found")
        const docRef = ref(db, `/notification/users/${selectedUser.profile.uid}/${notificationId}`)
        return set(docRef, {
            title, text, createAt: serverTimestamp(), alert: notificationAlert, notificationId, seen: false, inputDirection,
            textDirection,
        })
    })
    const docRef = ref(db, `/notification/manage/${notificationId}`)
    try {
        await set(docRef, {
            users: selectedUsers.map(user => user.profile.uid), info: {
                notificationId, title, text, createAt: serverTimestamp(), alert: notificationAlert, creatorId, inputDirection,
                textDirection,
            }, sendList: { selectedCourses, selectedRole, groupCode, customizeSelection }
        })
        await Promise.all(sendUserPromise)
    } catch (error) {
        toast.error(error.message)
        console.log(error)
    }
}

export const setNotificationSeen = async ({ notification, uid }) => {
    try {
        const docRef = ref(db, `/notification/users/${uid}/${notification.notificationId}`)
        if (!uid || !notification.notificationId) throw new Error("id not found")
        await update(docRef, { seen: true })
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const deleteRealItem = async (path) => {
    const docRef = ref(db, path)
    try {
        await remove(docRef)
        return "successfully remove data"
    } catch (error) {
        throw new Error(error)
    }
}
export const deleteStorageRecursively = async (folderPath) => {
    const storage = getStorage()
    const folderRef = storageRef(storage, folderPath);
    const listResult = await listAll(folderRef);

    // Delete all files in the folder
    try {

        const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
        await Promise.all(deletePromises);

        // Recursively delete all subfolders
        const subfolderPromises = listResult.prefixes.map((subfolderRef) => deleteStorageRecursively(subfolderRef.fullPath));
        await Promise.all(subfolderPromises);

        console.log("Folder and all its subfolders and files deleted successfully");
    } catch (error) {
        throw new Error(error)
    }
};

export const setResourcesIdInPath = async ({ path, pdf }) => {
    const docRef = ref(db, path)
    try {
        await update(docRef, { pdf })
        console.log("successfully set")
    } catch (error) {
        throw new Error(error)
    }
}
export const setResourcesVideoIdInPath = async ({ path, video }) => {
    const docRef = ref(db, path)
    try {
        await update(docRef, { video })
        console.log("successfully set")
    } catch (error) {
        throw new Error(error)
    }
}
export const setResourcesImagesIdInPath = async ({ path, images }) => {
    const docRef = ref(db, path)
    try {
        await update(docRef, { images })
        console.log("successfully set")
    } catch (error) {
        throw new Error(error)
    }
}


export const createEmptyLessonInDb = async ({ lesson }) => {
    if (!lesson.info.lessonId || !lesson.info.groupCode) {
        throw new Error("id or group not found")
    }
    // const { id, ...rawLesson } = lesson
    const lessonCopy = JSON.parse(JSON.stringify(lesson));
    lessonCopy.info.status = "draft"
    const docRef = ref(db, `/lact/${lesson.info.groupCode}/lessons/${lesson.info.lessonId}`)
    try {
        await set(docRef, lessonCopy)
    } catch (error) {
        throw new Error("there is a problem to set empty lesson")
    }
}
export const createTableInDb = async ({ table }) => {
    if (!table.info.tableId || !table.info.groupCode) {
        throw new Error("group code or id is not specified")
    }
    // const { id, ...rawtable } = table
    const docRef = ref(db, `lact/${table.info.groupCode}/tables/${table.info.tableId}`)
    try {
        await set(docRef, table)
    } catch (error) {
        throw new Error("there is a problem to set table")
    }
}


export const createEmptyCourseInDb = async ({ course }) => {
    console.log(course)
    if (!course.info.courseId) {
        throw new Error("id not found")
    }
    if (!course.info.groupCode) {
        throw new Error("please specify groupCode and try again later")
    }
    // const { id, ...rawcourse } = course
    const courseCopy = JSON.parse(JSON.stringify(course));
    courseCopy.info.status = "draft"
    const docRef = ref(db, `/lact/${course.info.groupCode}/courses/${course.info.courseId}/`)
    try {
        await set(docRef, courseCopy)
    } catch (error) {
        throw new Error("there is a problem to set empty course")
    }
}
export const createEmptyAssignmentInDb = async ({ assignment }) => {
    if (!assignment.info.assignmentId) {
        throw new Error("id not found")
    }
    if (!assignment.info.groupCode) {
        throw new Error("please specify groupCode and try again later")
    }
    // const { id, ...rawassignment } = assignment
    const assignmentCopy = JSON.parse(JSON.stringify(assignment));
    assignmentCopy.info.status = "draft"
    const docRef = ref(db, `/lact/${assignment.info.groupCode}/assignments/${assignment.info.assignmentId}`)
    try {
        await set(docRef, assignmentCopy)
    } catch (error) {
        throw new Error("there is a problem to set empty assignment")
    }
}

const googleProvider = new GoogleAuthProvider()
googleProvider.setCustomParameters({ prompt: "consent" })


googleProvider.addScope("https://www.googleapis.com/auth/chat.spaces");
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
// googleProvider.addScope('profile');
// googleProvider.addScope('email');
// // googleProvider.addScope('https://www.googleapis.com/auth/chat.messages');
// // googleProvider.addScope('https://www.googleapis.com/auth/chat.messages.readonly');
// // googleProvider.addScope('https://www.googleapis.com/auth/chat.spaces');
// // googleProvider.addScope('https://www.googleapis.com/auth/chat.spaces.readonly');
// googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
// googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
// googleProvider.addScope("https://www.googleapis.com/auth/chat.spaces");

export const authWithGoogle = async () => {
    const getData = async (accessToken) => {
        await fetch("https://chat.googleapis.com/v1/spaces", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.error("Error:", error));
    };
    const auth = getAuth()

    try {
        const userCredetial = await signInWithPopup(auth, googleProvider)
        // const accessToken = userCredetial.user.stsTokenManager; // Access token from Google OAuth
        const accessToken = userCredetial.user.accessToken;
        const tokenInfo = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`)
            .then(response => response.json());

        console.log("Token Info:", tokenInfo);

        console.log(accessToken)
        const somedata = await getData(accessToken)
        console.log(somedata)
        const idTokenResult = await userCredetial.user.getIdTokenResult();
        const groupCode = idTokenResult.claims.groupCode;
        if (!groupCode) throw new Error("there is a problem with claim")

        const docRef = ref(db, `/lact/${groupCode}/users/${auth.currentUser.uid}`)
        const userSnapshot = await get(docRef)

        if (!userSnapshot.exists()) {
            if (!userCredetial.user.uid) throw new Error("id not found")
            // const usersRef = ref(db, "/users")
            // const users = await get(usersRef)
            // const sameUser = Object.values(users).find((user) => user.profile.email === userCredetial.user.email)
            // if (sameUser) {
            //     const docRef = ref(db, `/users/${userCredetial.user.uid}`)
            //     await set(docRef, sameUser)
            //     await remove(`/users/${sameUser.profile.uid}`)
            // } else {
            throw new Error("ask university team to create account for you")
            // }
        } else {
            const docRef = ref(db, `/lact/${groupCode}/users/${auth.currentUser.uid}/profile`)
            await update(docRef, { lastLogin: Date.now(), email: userCredetial.user.email })
        }
    } catch (error) {
        console.log(error)
        await signOutUser()
        throw new Error(error)
    }

}

export const getSingleUser = async ({ groupCode, uid }) => {
    const docRef = ref(db, `/lact/${groupCode}/users/${uid}`)
    const resSnap = await get(docRef)
    return resSnap.val()
}

export const sendErrorToDb = async (error) => {
    const uid = auth?.currentUser?.uid
    const location = window.location.href
    const lastpart =
        location.split("/")[location.split("/").length - 1];
    if (!uid || !lastpart) {
        return
    }
    const errorRef = ref(db, `/errors/${uid}/${lastpart}`)
    try {
        // console.log(error.message)
        await update(errorRef, { error: error.message, route: location })
    } catch (error) {
        console.log(error)
    }
}


export const sendHomeData = async (data, path) => {
    const courseRef = ref(db, path)
    try {
        await set(courseRef, data)

    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const sendHomeImage = async (imageBlob, path) => {
    const storage = getStorage()
    const getDlUrl = async () => {
        return new Promise((resolve, reject) => {
            const imgRef = storageRef(storage, path);
            const uploadTask = uploadBytesResumable(imgRef, imageBlob);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    console.log("uploading image ...");
                },
                (error) => {
                    toast.error(`error in upload image${error}`);
                    reject(error);
                },
                () => {
                    console.log("image successfully uploaded");
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    });
                }
            );
        });
    };
    const dlUrl = await getDlUrl()
    await set(ref(db, path), { path, url: dlUrl })

}


export const changeCourseActive = async ({ groupCode, courseId, active }) => {
    if (!groupCode || !courseId) throw new Error("there is a problem with ID")
    const docRef = ref(db, `/lact/${groupCode}/courses/${courseId}/info`)
    try {
        await update(docRef, { active })
    } catch (error) {
        throw new Error(error)
    }
}