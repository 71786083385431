import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  InputAdornment,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteRealItem,
  deleteStorageRecursively,
  uploadGroup,
} from "../../../firebase.config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getGroupById } from "../../../redux/groupsSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getDocument } from "pdfjs-dist";
import { pdfjs } from "react-pdf";
import { GlobalWorkerOptions } from "pdfjs-dist";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LoadingButton from "../../../shared/comp/LoadingButton";
import { getUserById, getUsersByGroupcode } from "../../../redux/usersSlice";
import AddSemester from "./AddSemester";
import ViewFile from "../../../layout/ViewFile";

GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddNewGroup = () => {
  const canvasRef = useRef(null);
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(false);
  let { groupId } = useParams();
  const group = useSelector((state) => getGroupById(state, groupId));
  const [color, setColor] = useState(group?.color);
  const [code, setCode] = useState(group?.id);
  const [persianName, setPersianName] = useState(group?.persianName);
  const [englishName, setEnglishName] = useState(group?.englishName);
  // const fff = useSelector((state) => getUserById(state, group?.supervisor));
  const [supervisor, setSupervisor] = useState(group?.supervisor);
  const [pdf, setPdf] = useState(group?.catalog);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [numberOfPdfPage, setNumberOfPdfPages] = useState(
    pdf?.file?.pagesCount || 0
  );
  const [isOpen, setIsOpen] = useState(false);

  //   const [numOfPages, setNumOfPages] = useState(0);
  //   const [imagePath, setImagePath] = useState("");
  const [imageUrl, setImageUrl] = useState(pdf?.file?.url?.image || "");
  const [imagePath, setImagePath] = useState(pdf?.file?.path?.image || "");
  const [pdfPath, setPdfPath] = useState(pdf?.file?.path?.pdf || "");
  const [imageBlob, setImageBlob] = useState("");
  const storage = getStorage();
  const [progressBarContent, setProgressBarContent] = useState(0);
  const [localUrl, setLocalUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState(pdf?.file?.url?.pdf || "");
  const usersGroup = useSelector((state) => getUsersByGroupcode(state, code));
  const instructorsList = usersGroup.filter(
    (user) => user.profile.role === "instructor"
  );

  const changeUser = (e) => {
    setSupervisor(e.target.value);
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      await uploadGroup({
        color,
        persianName,
        englishName,
        supervisor,
        id: code,
        catalog: pdf || "",
      });
      toast.success("group created successfully");
      navigate("/adminpanel/groups");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!localUrl) return;
    const saveLocalUrl = async () => {
      const pdf = await getDocument(localUrl).promise;
      const page = await pdf.getPage(1);
      setNumberOfPdfPages(pdf.numPages);

      // Increase the scale factor for better quality
      const scale = 2; // Adjust this value as needed
      const viewport = page.getViewport({ scale });

      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const imgDataUrl = canvas.toDataURL("image/png");
      const imageRes = await fetch(imgDataUrl);
      const imageBlobI = await imageRes.blob();
      setImageBlob(imageBlobI);
    };
    saveLocalUrl();
  }, [localUrl]);

  useEffect(() => {
    if (!imageBlob) return;
    const saveDataInDb = async () => {
      setLoading(true);
      try {
        const pdfLink = await storePdfFile();
        const imageLink = await storeImageFile();
        const pdfToUpload = {
          file: {
            path: {
              image: imagePath,
              pdf: pdfPath,
            },
            url: { image: imageLink, pdf: pdfLink },
            pagesCount: numberOfPdfPage,
          },
        };
        // await postPdfDataToDb(pdfToUpload, groupCode);
        setPdf(pdfToUpload);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("upload failed");
      }
    };
    saveDataInDb();
  }, [imageBlob]);

  const onInputFile = async (e) => {
    if (!e.target.files.length) return;
    if (!code) {
      toast.error("specify group code first");
      e.target.value = "";
      return;
    }
    setPdfPath(`/majors/${code}/catalog/pdf`);
    setImagePath(`/majors/${code}/catalog/image.png`);
    const lclUrl = URL.createObjectURL(e.target.files[0]);

    setLocalUrl(lclUrl);
    setSelectedFile(e.target.files[0]);
  };

  const removeOneSelectedFile = async () => {
    if (!code) throw new Error("id not found");
    if (window.confirm("are you want to proceed removing pdf ?")) {
      try {
        await deleteRealItem(`/groups/${code}/catalog`);
        await deleteStorageRecursively(`/majors/${code}/catalog`);
        setSelectedFile("");
        setProgressBarContent("");
        setImageUrl("");
        setImagePath("");
        setPdfUrl("");
        setPdfPath("");
        setLocalUrl("");
        inputRef.current.value = "";
        toast.success("successfully deleted");
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const storeImageFile = async () => {
    if (!code) return;
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `/majors/${code}/catalog/image.png`);
      const uploadTask = uploadBytesResumable(storageRef, imageBlob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("uploading image ...");
        },
        (error) => {
          toast.error(`error in upload image${error}`);
          reject(error);
        },
        () => {
          console.log("image successfully uploaded");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            setImagePath(`/majors/${code}/catalog/image.png`);
            setImageUrl(downloadURL);
            // setImageUrlOnStorage(downloadURL);
          });
        }
      );
    });
  };

  const storePdfFile = () => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, pdfPath);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressBarContent(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          toast("file successfully uploaded to storage");
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setPdfUrl(downloadURL);
            setPdfPath(`/majors/${code}/catalog/pdf`);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "400px",
        margin: "auto",
        padding: 3,
        borderRadius: 2,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
        marginTop: "20px",
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 2 }}>
        Group Form
      </Typography>

      <TextField
        label="Color (Hex)"
        variant="outlined"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: color,
                  border: "1px solid #ccc",
                }}
              />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label="Import Code"
        variant="outlined"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        fullWidth
      />

      <TextField
        label="Persian Name"
        variant="outlined"
        value={persianName}
        onChange={(e) => setPersianName(e.target.value)}
        fullWidth
      />

      <TextField
        label="English Name"
        variant="outlined"
        value={englishName}
        onChange={(e) => setEnglishName(e.target.value)}
        fullWidth
      />

      <FormControl fullWidth>
        <InputLabel id="user-select-label">Select User</InputLabel>
        <Select
          labelId="user-select-label"
          onChange={changeUser}
          label="Select User"
          className="mb-10"
        >
          {instructorsList.map((user, index) => (
            <MenuItem
              key={index}
              value={user.profile.uid}
              selected={supervisor === user.profile.uid}
            >
              {user.profile.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        create semester
      </Button>
      <ViewFile
        isOpen={isOpen}
        onClose={onClose}
        title={"Select Home Page Courses"}
      >
        <AddSemester />
      </ViewFile>
      <div className="flex flex-col">
        <Button
          component="label"
          role={undefined}
          tabIndex={-1}
          size="small"
          sx={{ fontWeight: "900" }}
        >
          <CloudUploadIcon sx={{ fontSize: "40px" }} className="text-cu-blue" />
          <VisuallyHiddenInput
            type="file"
            onChange={onInputFile}
            accept={".pdf"}
            multiple={false}
            ref={inputRef}
          />
          <span className="ms-4 text-cu-blue">Upload pdf</span>
        </Button>
        {(selectedFile || pdfUrl) && (
          <div className="flex flex-col items-center border p-3 mt-3">
            <div className="m-auto text-center">
              {pdfUrl && (
                <span
                  style={{ cursor: "pointer" }}
                  className="text-red-600"
                  onClick={(e) => removeOneSelectedFile()}
                >
                  X
                </span>
              )}

              <div>
                <canvas
                  ref={canvasRef}
                  className={`rounded-md m-auto hidden `}
                ></canvas>

                {/* {!imageUrl && localUrl && (
                      <div className="flex items-center justify-center">
                        <img
                          width={200}
                          height={200}
                          src={localUrl}
                          alt="Selected frame"
                        />
                      </div>
                    )} */}
                {imageUrl && (
                  <div className="flex items-center justify-center">
                    <img
                      width={200}
                      height={200}
                      src={imageUrl}
                      alt="Selected frame"
                    />
                  </div>
                )}
                {!imageUrl && (
                  <div
                    className={`w-full rounded-full bg-slate-200 mt-2 ${
                      !progressBarContent ? "hidden" : "block"
                    } }`}
                  >
                    <PictureAsPdfIcon sx={{ fontSize: "50px" }} />
                    <div
                      className={` text-center bg-blue-500 rounded-full ${
                        progressBarContent === 100 && "bg-green-500"
                      }`}
                      style={{ width: `${progressBarContent}%` }}
                    >
                      {Math.floor(progressBarContent)}%
                    </div>
                  </div>
                )}
                {numberOfPdfPage && (
                  <p className="text-center py-5"> {numberOfPdfPage} pages</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div onClick={submitForm}>
        <LoadingButton
          loading={loading}
          disabled={!color || !code || !persianName || !englishName}
          name={"SUBMIT"}
        />
      </div>
    </Box>
  );
};

export default AddNewGroup;
