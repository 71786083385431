import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAllCourses,
  getCourseById,
  getCoursesByIds,
} from "../../../redux/coursesSlice";
import { useSelector } from "react-redux";
import { getLessonsByIds } from "../../../redux/lessonsSlice";
import EachLessonContentDrip from "./EachLessonContentDrip";
import { toast } from "react-toastify";
import { updateContentDripToDb } from "../../../firebase.config";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
// import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const ContentDrip = () => {
  // const { courseId } = useParams();
  const user = useSelector((state) => state.user);
  // const role = currentUser.profile.role;
  // const instructorCoursesId = currentUser.assignedCourses
  //   ? Object.keys(currentUser.assignedCourses)
  //   : [];

  // const instructorCourses = useSelector((state) =>
  //   getCoursesByIds(state, instructorCoursesId)
  // );
  const courses = useSelector(getAllCourses);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [loading, setLoading] = useState(false);

  const changeCourse = (e) => {
    const course = courses.find(
      (course) => course.info.courseId === e.target.value
    );
    setSelectedCourse(course);
  };

  const [assignmentDateList, setAssignmentDateList] = useState([]);
  const [lessonDateList, setLessonDateList] = useState([]);

  const assignedLessons = selectedCourse.assignedLessons
    ? Object.keys(selectedCourse.assignedLessons)
    : [];
  const lessonInCourse = useSelector((state) =>
    getLessonsByIds(state, [...assignedLessons])
  );

  const updateReleaseDates = async () => {
    if (!assignmentDateList.length && !lessonDateList.length) {
      toast.error("there is nothing to update");
      return;
    }
    try {
      setLoading(true);
      const res = await updateContentDripToDb(
        assignmentDateList,
        lessonDateList,
        selectedCourse.info.groupCode
      );
      toast.success(res);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="mt-10 w-9/12 m-auto">
      <FormControl fullWidth>
        <InputLabel id="course-select-label">Select Course</InputLabel>
        <Select
          labelId="course-select-label"
          onChange={changeCourse}
          label="Select Course"
          className="mb-10"
        >
          {courses.map((course, index) => (
            <MenuItem key={index} value={course.info.courseId}>
              {course.info.courseId} --- {course.info.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="flex flex-col">
        {lessonInCourse.map((lesson, index) => (
          <div
            className={`${index % 2 ? "bg-slate-100" : "bg-slate-200"} px-3`}
          >
            <EachLessonContentDrip
              key={"lesson" + index}
              lesson={lesson}
              setAssignmentDateList={setAssignmentDateList}
              setLessonDateList={setLessonDateList}
              lessonDateList={lessonDateList}
            />
          </div>
        ))}
      </div>
      {selectedCourse && (
        <div className="mt-10 ms-10">
          <Button
            loading={loading}
            loadingPosition="end"
            variant="contained"
            onClick={updateReleaseDates}
          >
            update
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContentDrip;
