import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

const EachLessonAssign = ({ lesson, changeList, assignedLessons }) => {
  const [assign, setAssign] = useState(false);

  useEffect(() => {
    const ifChecked = assignedLessons.includes(lesson.info.lessonId);
    setAssign(ifChecked);
    if (ifChecked) {
      changeList({
        assign: true,
        lessonId: lesson.info.lessonId,
        // title: lesson.info.title,
      });
    }
  }, []);
  // const [assignedAnother, setAssignedAnother] = useState("");

  const changeAssignLesson = async (e) => {
    setAssign((prev) => {
      changeList({
        assign: !prev,
        lessonId: lesson.info.lessonId,
      });
      return !prev;
    });
  };

  return (
    <p className="flex justify-between">
      {/* <p className="me-5">{lesson.info.lessonId}</p> */}
      <p className="text-start">{lesson.info.title}</p>
      <input type="checkbox" checked={assign} onChange={changeAssignLesson} />
    </p>
  );
};

export default EachLessonAssign;
