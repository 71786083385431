import React from "react";
import {
  Container,
  Paper,
  Avatar,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import CourseStudentProfile from "./CourseStudentProfile";
import AboutMe from "./AboutMe";
import { useNavigate } from "react-router-dom";
import { getGroupById } from "../../../redux/groupsSlice";
const ShowStudentProfile = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const group = useSelector((state) =>
    getGroupById(state, user.profile.groupCode)
  );

  return (
    <Container maxWidth="w-full">
      <Paper elevation={3} sx={{ padding: "20px", marginTop: "20px" }}>
        {/* <div
          className="text-right"
          onClick={() => navigate("/studentpanel/profile/addnewessay")}
        >
          <Button>
            Add Essay <AddCircleOutlineIcon />
          </Button>
        </div> */}
        <Box display="flex" justifyContent="center" mb={2}>
          <Avatar
            src={user?.profile?.photoUrl?.url}
            alt={user?.profile?.name}
            style={{ width: 100, height: 100 }}
          />
        </Box>
        <Typography variant="h5" align="center" gutterBottom>
          {user?.profile?.name} {user?.profile?.family}
        </Typography>
        <AboutMe />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Major: </strong> {group.englishName}
            </Typography>
          </Grid>
          {user.profile.role === "student" && (
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Student Number:</strong> {user?.profile?.studentNumber}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Email:</strong> {user?.profile.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Address:</strong> {user?.address?.country},{" "}
              {user?.address?.state}, {user?.address?.city},{" "}
              {user?.address?.street}, {user?.address?.unit}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Zip Code:</strong> {user?.address?.zipCode}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Mobile Number:</strong> {user?.address?.phoneNumber}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" className="flex gap-x-3 flex-wrap">
              <strong>Course Enrolled:</strong>{" "}
              {user?.assignedCourses &&
                Object.keys(user?.assignedCourses).map((course) => (
                  <CourseStudentProfile courseId={course} />
                ))}
            </Typography>
          </Grid>
        </Grid>
        {/* <ShowEssays /> */}
      </Paper>
    </Container>
  );
};

export default ShowStudentProfile;
