import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Login from "./pages/auth/login/Login"
import AdminPanel from './panels/layout/adminLayout/AdminPanel';
import ChangePassword from './pages/auth/ChangePassword';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { db, userAuthChange } from './firebase.config';
import { getUserProfile } from './redux/userSlice';
import { get, onValue, ref, set, update } from 'firebase/database';
import StudentPanel from './panels/layout/studentLayout/StudentPanel';
import ViewFile from './layout/ViewFile';
import { addgroups } from './redux/groupsSlice';
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandlerComp from './errorHandler/ErrorHandlerComp';
import { toast } from 'react-toastify';
import InstructorPanel from './panels/layout/instructorLayout/InstructorPanel';
import axios from 'axios';
import NotFound from './pages/NotFound';
import About from './pages/InformationPages/About';
import TermsOfService from './pages/InformationPages/TermsOfService';
import PrivacyPolicy from './pages/InformationPages/PrivacyPolicy';
import GeneralPanel from './pages/GeneralPanel';
import SupervisorPanel from './panels/layout/supervisorLayout/SupervisorPanel';
import Home from './pages/home/Home';
import { addPublicInfo } from './redux/publicInfoSlice';
import LoadingOverlay from './shared/LoadingOverlay';
// import InstructorPanel from './panels/instructor/instructorLayout/InstructorPanel';


function App() {
  const [loading, isLoading] = useState(true)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const profile = user?.profile
  // window.addEventListener('unhandledrejection', (event) => {
  //   console.error('Unhandled promise rejection:', event.promise, 'reason:', event.reason);
  //   // Handle the error globally
  // });





  useEffect(() => {
    const unsubscribe = userAuthChange(async (currentUser) => {
      // console.log(user.currentUser.stsTokenManager.refreshToken, user.currentUser.stsTokenManager.accessToken)

      //------use custom claim in client side
      // const idTokenResult = await currentUser.getIdTokenResult();
      // console.log('Custom claims:', idTokenResult.claims);
      // const createData = async () => {
      //   const currentuser = getUser()
      //   console.log(currentuser)
      //   const usersRef = ref(db, "/users/kwXxdmxvwYP0goeVhNVyKetpQov1")
      //   await set(usersRef, {
      //     profile: {
      //       name: "kourosh",
      //       family: "masoumi",
      //       email: "admin@cyrusuniversity.org",
      //       role: "admin",
      //       uid: "kwXxdmxvwYP0goeVhNVyKetpQov1",
      //       photoUrl: "",
      //       createAt: serverTimestamp(),
      //       lastLogin: serverTimestamp()
      //     }, address: {},
      //   })
      // }
      // createData()
      //temp admin creator
      if (!currentUser) return

      //?
      if (!profile?.email) {
        dispatch(getUserProfile(currentUser))
      }
    })
    return unsubscribe
  }, [dispatch, profile?.email])
  // useEffect(() => {
  //   const pdfsRef = ref(db, "/pdf");
  //   return onValue(pdfsRef, (snap) => {
  //     const pdfsArray = [];
  //     if (snap.val()) {
  //       Object.keys(snap.val()).forEach((key) => {
  //         pdfsArray.push({
  //           id: key,
  //           ...snap.val()[key],
  //         });
  //       });
  //       dispatch(setPdfs(pdfsArray));
  //     }
  //   });
  // }, [dispatch]);

  // useEffect(() => {
  //   const videosRef = ref(db, "/video");
  //   return onValue(videosRef, (snap) => {
  //     const videosArray = [];
  //     if (snap.val()) {
  //       Object.keys(snap.val()).forEach((key) => {
  //         videosArray.push({
  //           id: key,
  //           ...snap.val()[key],
  //         });
  //       });
  //       dispatch(setVideos(videosArray));
  //     }
  //   });
  // }, [dispatch]);

  // useEffect(() => {
  //   const imagesRef = ref(db, "/image");
  //   return onValue(imagesRef, (snap) => {
  //     const imagesArray = [];
  //     if (snap.val()) {
  //       Object.keys(snap.val()).forEach((key) => {
  //         imagesArray.push({
  //           id: key,
  //           ...snap.val()[key],
  //         });
  //       });
  //       dispatch(setImages(imagesArray));
  //     }
  //   });
  // }, [dispatch]);


  // useEffect(() => {
  //   const tablesRef = ref(db, "/tables");
  //   return onValue(tablesRef, (snap) => {
  //     const tablesArray = [];
  //     if (snap.val()) {
  //       Object.keys(snap.val()).forEach((key) => {
  //         tablesArray.push({
  //           id: key,
  //           ...snap.val()[key],
  //         });
  //       });
  //       dispatch(addtables(tablesArray));
  //     }
  //   });
  // }, [dispatch]);



  // useEffect(() => {
  //   const myf = async () => {

  //     const resourcesRef = ref(db, `/resources/04`);
  //     const snap = await get(resourcesRef)
  //     if (snap.val()) {
  //       const videoArr = Object.values(snap.val().video).map((v) => {
  //         return ({ file: v.file, info: { ...v.info, groupCode: "04" }, id: v.info.videoId })
  //       })
  //       const imageArr = Object.values(snap.val().image).map((v) => {
  //         return ({ files: v.files, info: { ...v.info, groupCode: "04" }, id: v.info.imageId })
  //       })
  //       const pdfArr = Object.values(snap.val().pdf).map((v) => {
  //         return ({ file: v.file, info: { ...v.info, groupCode: "04" }, id: v.info.pdfId })
  //       })

  //       const videoObj = videoArr.reduce((acc, curr) => {
  //         acc[curr.info.videoId] = curr
  //         return acc
  //       }, {})
  //       const imageObj = imageArr.reduce((acc, curr) => {
  //         acc[curr.info.imageId] = curr
  //         return acc
  //       }, {})
  //       const pdfObj = pdfArr.reduce((acc, curr) => {
  //         acc[curr.info.pdfId] = curr
  //         return acc
  //       }, {})
  //       // console.log(imageObj)
  //       const newRef = ref(db, "/resources/04")
  //       update(newRef, { image: imageObj, video: videoObj, pdf: pdfObj })
  //     }
  //   }
  //   myf()
  // }
  //   , [])



  useEffect(() => {
    const groupRef = ref(db, "/groups");
    return onValue(groupRef, (snap) => {
      const groupsArray = [];
      if (snap.val()) {
        Object.keys(snap.val()).forEach((key) => {
          groupsArray.push({
            id: key,
            ...snap.val()[key],
          });
        });
        dispatch(addgroups(groupsArray));
      }
    },
      (error) => {
        toast.error(error.message);
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    const groupRef = ref(db, "/publicInfo");
    return onValue(groupRef, (snap) => {
      const groupsArray = [];
      if (snap.val()) {
        Object.keys(snap.val()).forEach((key) => {
          groupsArray.push({
            id: key,
            ...snap.val()[key],
          });
        });
        dispatch(addPublicInfo(groupsArray));
        isLoading(false)
      }
    },
      (error) => {
        toast.error(error.message);
        console.log(error);
      });
  }, [dispatch]);




  return (
    <ErrorBoundary FallbackComponent={ErrorHandlerComp} onReset={() => {
      window.location.href = '/';
    }}>
      <Router>
        <Routes>
          {/* <Route path='/viewfile' element={<ViewFile />} /> */}
          {user?.profile?.role === "admin" && <Route path='/adminpanel/*' element={<AdminPanel />} />}
          {user?.profile?.role === "student" && <Route path='/studentpanel/*' element={<StudentPanel />} />}
          {user?.profile?.role === "instructor" && <Route path='/instructorpanel/*' element={<InstructorPanel />} />}
          {user?.profile?.role === "supervisor" && <Route path='/supervisorpanel/*' element={<SupervisorPanel />} />}
          <Route path="/login" element={<Login />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path='/*' element={<GeneralPanel />} />
          {/* <Route element={<Layout />}>
          <Route path='/register' element={<Register />} />
          <Route index element={<Home />} />
          <Route path='/resourses/*' element={<Resourses />} />
        </Route> */}
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;


// const ccccc = async () => {
//   if (currentUser) {
//     const userRef = ref(db, "/users/" + currentUser.uid)
//     await set(userRef, {
//       profile: {
//         name: "koroush",
//         family: "masoumi",
//         email: "admin@cyrusuniversity.org",
//         role: "admin",
//         photoUrl: "",
//         uid: "MzVec4ZBdgXstVEjUnnqrQ1KCNn1"
//       }
//     })
//   }
// }
// ccccc()