import React, { useState, useRef, useEffect } from "react";
import { Button, FormControlLabel, Switch } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { changeCourseActive } from "../../../firebase.config";
import { toast } from "react-toastify";

const ActionsCourseMenu = ({ onView, onEdit, onDelete, course }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [active, setActive] = useState(course?.info?.active ?? false);
  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeActive = async () => {
    setActive(!active);
    try {
      await changeCourseActive({
        courseId: course.info.courseId,
        groupCode: course.info.groupCode,
        active: !active,
      });
      toast.success("success");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="absolute right-0 top-0 text-blue-600 h-full me-1 flex items-center cursor-pointer">
      <Button onClick={openMenu}>
        <MoreHorizIcon className="text-cu-blue" />
      </Button>
      {menuOpen && (
        <div
          ref={menuRef}
          className="absolute top-full right-0 bg-white shadow-lg p-1 z-40 w-36 flex flex-col gap-y-2 items-start rounded-xl border"
        >
          <Button
            onClick={onView}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
            }}
            className="gap-x-2 w-full "
          >
            <PreviewIcon className="text-blue-500" sx={{ width: "20px" }} />
            <span>Review</span>
          </Button>
          <Button
            onClick={onEdit}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
            }}
            className="w-full gap-x-2"
          >
            <EditIcon sx={{ width: "20px" }} />
            <span>Edit</span>
          </Button>
          <Button
            onClick={onDelete}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
              color: "#bf0a30",
            }}
            className=" w-full gap-x-2"
          >
            <DeleteForeverOutlinedIcon sx={{ width: "20px" }} /> Delete
          </Button>
          <FormControlLabel
            label="Active"
            labelPlacement="start"
            control={
              <Switch
                color="success"
                checked={active}
                onChange={changeActive}
                name="Active"
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default ActionsCourseMenu;
