import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import NavigationLink from "../sharedComp/NavigationLink";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CrouseGeneralSettings from "./courseSettings/CrouseGeneralSettings";
import { onValue, ref, serverTimestamp, set } from "firebase/database";
import { createACourse, db } from "../../../firebase.config";
import { useDispatch, useSelector } from "react-redux";
import CategoryAdd from "../category/CategoryAdd";
import { toast } from "react-toastify";
import CourseImageSettings from "./courseSettings/CourseImageSettings";
import LessonAssign from "./lessonAssign/LessonAssign";
import {
  createCourse,
  getCourseById,
  removeNotPublishedCourse,
} from "../../../redux/coursesSlice";
import { Button, FormControlLabel, Switch } from "@mui/material";
import TextAreaComponent from "../sharedComp/TextAreaComponent";
import GroupCodeSection from "../sharedComp/GroupCodeSection";
import ShowAddedResources from "../sharedComp/addResource/ShowAddedResources";
import ChooseMultipleCategories from "../sharedComp/ChooseMultipleCategories";
import { getAllCategories } from "../../../redux/CategoriesSlice";
import InputMultiplePdf from "../sharedComp/multiplePdf/InputMultiplePdf";
import UploadMultiplePdf from "../sharedComp/multiplePdfU/UploadMultiplePdf";
import CourseMaterials from "./courseSettings/CourseMaterials";

const EditCourse = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const user = useSelector((state) => state.user);
  let course = useSelector((state) => getCourseById(state, courseId));
  const pdfsInDb = course?.material ? Object.values(course.material) : [];
  const dispatch = useDispatch();
  const [active, setActive] = useState(course?.info?.active);
  const general = course?.general;
  const [unit, setUnit] = useState(general?.unit);
  const [level, setLevel] = useState(general?.level);
  const [catName, setCatName] = useState(course?.categoriesName || []);
  const [necessity, setNecessity] = useState(general?.necessity || "main");
  const [duration, setDuration] = useState(general?.duration);
  const [durationUnit, setDurationUnit] = useState(general?.durationUnit);
  const [prerequisite, setPrerequisite] = useState(general?.prerequisite || []);
  const [selectedInstructor, setSelectedInstructor] = useState(
    course?.assignedInstructor
      ? Object.keys(course.assignedInstructor)[0]
      : user.profile.role === "instructor"
      ? user?.profile?.uid
      : null
  );
  const [groupCode, setGroupCode] = useState(
    course?.info?.groupCode ||
      (user.profile.role === "instructor" ? user.profile.groupCode : null)
  );
  // const [groupCode, setGroupCode] = useState(
  //   course?.info?.groupCode ||
  //     (user.profile.role === "instructor" ? user.profile.groupCode : "01")
  // );

  const [inputDirection, setInputDirection] = useState(
    course?.info?.titleDirection || "rtl"
  );

  const location = useLocation();

  //(course?.categoriesName && Object.values(course?.categoriesName))
  const lastpart =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const [title, setTitle] = useState(course?.info?.title);
  const [explain, setExplain] = useState(course?.info?.explain);
  const [nameDirection, setNameDirection] = useState(
    course?.info?.nameDirection || "rtl"
  );
  const [name, setName] = useState(course?.info?.name);
  // const [courseId, setCourseId] = useState(course.info.courseId);
  const currentCourse = useSelector((state) => getCourseById(state, courseId));

  const editCourse = async () => {
    if (title.length < 3) {
      toast.error("title must have 4 letter or more");
      return;
    }
    const courseToSend = {
      ...currentCourse,
      info: {
        groupCode,
        creatorId: user.profile.uid,
        courseId,
        title,
        explain,
        editAt: serverTimestamp(),
        createAt: course?.info.createAt,
        status: "published",
        titleDirection: inputDirection,
        name,
        nameDirection,
        active,
      },
      general: {
        duration,
        durationUnit,
        prerequisite,
        level,
        unit,
        necessity,
      },
      categoriesName: catName,
      assignedInstructor: { [selectedInstructor]: groupCode },
    };
    await createACourse(courseToSend);
    toast.success("successfully add new course");
    navigate(`/${user.profile.role}panel/courses`);
  };

  useEffect(() => {
    if (!location.pathname.includes("addnewcourse") || !courseId) return;

    const newCourseInitialState = {
      // categoriesName: catName,
      info: {
        courseId,
        title: "",
        explain: "",
        editAt: "",
        createAt: serverTimestamp(),
        status: "temp",
        creatorId: user.profile.uid,
        groupCode:
          course?.info?.groupCode ||
          (user.profile.role === "instructor" && user?.profile?.groupCode) ||
          "",
        titleDirection: inputDirection,
        name,
        nameDirection,
        active: false,
        groupList: [],
      },
      general: {
        duration: 0,
        durationUnit: "week",
        prerequisite: [],
        level: "all",
        unit: 0,
        necessity: "",
      },
      assignedInstructor: "",
      files: {
        courseImage: "",
      },
    };
    dispatch(createCourse({ id: courseId, ...newCourseInitialState }));

    return () => {
      if (course?.info?.status !== "temp") return;
      dispatch(removeNotPublishedCourse(courseId));
    };
  }, [courseId, dispatch]);

  return (
    <div className="mx-5 mt-5">
      <div className="mb-3 flex justify-between">
        <div className="flex items-center gap-x-3">
          <h2>
            {location.pathname.includes("addnewcourse")
              ? "Add New Course"
              : "Edit Course"}
          </h2>
          <FormControlLabel
            control={
              <Switch
                color="success"
                checked={active}
                onChange={() => {
                  setActive(!active);
                }}
                name="availibility"
              />
            }
          />
        </div>
        <h2>{courseId}</h2>
        <Button className="border w-28 self-end" onClick={editCourse}>
          Publish
        </Button>
      </div>
      <TextAreaComponent
        props={{
          explain,
          setTitle,
          title,
          inputDirection,
          setInputDirection,
          setExplain,
          groupCode: course?.info?.groupCode,
          name,
          nameDirection,
          setName,
          setNameDirection,
        }}
      />
      {/* <ShowAddedResources
          section={course}
          sectionName={"courses"}
          sectionId={courseId}
          status={course.info?.status}
          groupCode={course.info?.groupCode}
        /> */}
      <div className="border border-slate-400 flex">
        <ul class="flex flex-col bg-slate-100 w-60 border text-blue-500 pb-40">
          {/* <NavigationLink path={"./author"} name="Author" /> */}
          <NavigationLink path={"./general"} name="Settings" />
          <NavigationLink path={"./addresourse"} name="Add Resources" />
          <NavigationLink path={"./coursematerial"} name="Course Material" />
        </ul>
        <div className="py-4 w-full">
          {lastpart === "general" && (
            <CrouseGeneralSettings
              generalProps={{
                unit,
                setUnit,
                level,
                setLevel,
                catName,
                setCatName,
                necessity,
                setNecessity,
                duration,
                setDuration,
                durationUnit,
                setDurationUnit,
                prerequisite,
                setPrerequisite,
                selectedInstructor,
                setSelectedInstructor,
                groupCode,
                setGroupCode,
              }}
            />
          )}
          {lastpart === "addresourse" && (
            <ShowAddedResources
              section={course}
              sectionName={"courses"}
              sectionId={courseId}
              status={course?.info?.status}
              groupCode={course?.info?.groupCode}
            />
          )}
          {lastpart === "coursematerial" && (
            <CourseMaterials groupCode={groupCode} />
          )}
          {/* {lastpart === "material" && (
            <InputMultiplePdf/>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default EditCourse;
