import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getLessonsByIds } from "../../../redux/lessonsSlice";
import EachLessonSidebar from "./EachLessonSidebar";

const SelectedListItemButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: "#002868",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#003a99",
  },
  "&.Mui-selected": {
    color: "#bf0a30",
    backgroundColor: "#001f4d",
    "&:hover": {
      backgroundColor: "#004bb3",
    },
  },
}));

const EachCourseSidebar = ({ course, setCourseIndex, courseIndex, index }) => {
  const [lessonIndex, setLessonIndex] = useState(0);
  const [nestedOpen, setNestedOpen] = useState(false);
  const lessons = useSelector((state) =>
    getLessonsByIds(
      state,
      course?.assignedLessons ? Object.keys(course.assignedLessons) : []
    )
  );

  const navigate = useNavigate();
  const openCourse = (course) => {
    // if (course?.publishDate > Date.now()) {
    //   return;
    // }
    setLessonIndex(null);
    setCourseIndex(index);
    setNestedOpen((prev) => !prev);
    navigate(`courses/viewcourse/${course?.id}`);
  };
  console.log(courseIndex === index);
  return (
    <div className="bg-[#001f4d]">
      <SelectedListItemButton
        selected={courseIndex === index}
        onClick={() => openCourse(course)}
      >
        {/* <Button
          className="w-full "
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "white",
          }}
        > */}
        <ListItemText> {course?.info.title}</ListItemText>
        {/* <span>
            {" "}
            {course?.info.publishDate > Date.now() ? (
              <LockIcon className="text-cu-blue" style={{ fontSize: "20px" }} />
            ) : (
              ""
            )}
          </span> */}
        {/* </Button> */}
        {nestedOpen && courseIndex === index ? <ExpandLess /> : <ExpandMore />}
      </SelectedListItemButton>
      {/* <div className="w-48 m-auto"> */}
      <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className="flex flex-col gap-y-3">
          {Object.values(lessons).map((lesson, index) => (
            <EachLessonSidebar
              lesson={lesson}
              index={index}
              lessonIndex={lessonIndex}
              setLessonIndex={setLessonIndex}
            />
          ))}
        </List>
      </Collapse>
      {/* </div> */}
    </div>
  );
};

export default EachCourseSidebar;
