import React, { useEffect, useState } from "react";
import AddNamesInput from "../../sharedComp/AddNamesInput";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addOtherInfo,
  getCourseById,
  updateGroupCode,
} from "../../../../redux/coursesSlice";
import CourseImageSettings from "./CourseImageSettings";
import GroupCodeSection from "../../sharedComp/GroupCodeSection";
import ChooseMultipleCategories from "../../sharedComp/ChooseMultipleCategories";
import LessonAssign from "../lessonAssign/LessonAssign";
import { getAllCategories } from "../../../../redux/CategoriesSlice";
import Test from "./Test";
import SelectUserComp from "../../sharedComp/SelectUserComp";
import { getAllUsers } from "../../../../redux/usersSlice";
// import Test from "./Test";

const CrouseGeneralSettings = ({ generalProps }) => {
  const {
    unit,
    setUnit,
    level,
    setLevel,
    catName,
    setCatName,
    necessity,
    setNecessity,
    duration,
    setDuration,
    durationUnit,
    setDurationUnit,
    prerequisite,
    setPrerequisite,
    selectedInstructor,
    setSelectedInstructor,
    groupCode,
    setGroupCode,
  } = generalProps;
  const users = useSelector(getAllUsers);
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const course = useSelector((state) => getCourseById(state, courseId));
  const createAt = course?.info?.createAt;

  const [deadline, setDeadline] = useState("none");
  const categories = useSelector(getAllCategories);
  const user = useSelector((state) => state.user);

  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    if (!groupCode) return;
    setInstructors(
      users.filter((user) => {
        return (
          user.profile.role === "instructor" &&
          user.profile.groupCode === groupCode
        );
      })
    );
  }, [groupCode, users]);

  //   const [lifeTime, setLifeTime] = useState(true);
  // useEffect(() => {
  //   if (!groupCode) return;
  //   dispatch(
  //     addOtherInfo({
  //       id: courseId,
  //       changes: {
  //         general: {
  //           duration,
  //           durationUnit,
  //           prerequisite,
  //           level,
  //           unit,
  //           necessity,
  //         },
  //         categoriesName: catName,

  //         // info: { ...course.info, groupCode },
  //       },
  //     })
  //   );
  // }, [
  //   catName,
  //   courseId,
  //   dispatch,
  //   duration,
  //   durationUnit,
  //   groupCode,
  //   level,
  //   necessity,
  //   prerequisite,
  //   selectedInstructor?.profile?.uid,
  //   unit,
  // ]);
  // useEffect(() => {
  //   if (!groupCode || !course?.assignedInstructor) return;

  //   dispatch(
  //     addOtherInfo({
  //       id: courseId,
  //       changes: {
  //         assignedInstructor: { [selectedInstructor?.profile?.uid]: groupCode },
  //       },
  //     })
  //   );
  // }, [courseId, dispatch, groupCode, selectedInstructor?.profile?.uid]);

  useEffect(() => {
    if (!groupCode) return;
    dispatch(updateGroupCode({ groupCode, courseId }));
  }, [groupCode]);

  const changeDurationFunc = (num) => {
    setDuration(num >= 0 ? num : 0);
  };

  useEffect(() => {
    let courseDuration;
    if (durationUnit === "week") {
      courseDuration = 604800000 * duration;
    } else if (durationUnit === "day") {
      courseDuration = 86400000 * duration;
    } else if (durationUnit === "hour") {
      courseDuration = 3600000 * duration;
    }
    if (+duration) {
      setDeadline(courseDuration + createAt);
    } else {
      setDeadline("none");
    }
  }, [duration, durationUnit, createAt]);

  return (
    <div className="flex flex-col p-10 pt-2 gap-y-10">
      <div className="flex items-center">
        <label className="w-44 items-center">Group Code</label>

        {user.profile.role === "admin" && (
          <div className="w-96">
            <GroupCodeSection
              disabled={course?.info?.status && course?.info?.status !== "temp"}
              groupCode={groupCode}
              setGroupCode={setGroupCode}
            />
          </div>
        )}
      </div>

      <div className="flex items-center">
        <p className="w-44 ">Assigned Instructor</p>
        <div className="w-96">
          <SelectUserComp
            users={instructors}
            selectedUser={selectedInstructor}
            setSelectedUser={setSelectedInstructor}
          />
        </div>
        {/* <Test /> */}
      </div>

      <div className="flex items-center">
        <p className="w-44 ">Assigned Lesson</p>
        <div className="w-96">
          {" "}
          <LessonAssign course={course} groupCode={groupCode} />
        </div>

        {/* <Test /> */}
      </div>
      <div className="flex items-center">
        <p className="w-44">Categories</p>
        <div className="w-96">
          <ChooseMultipleCategories
            catName={catName}
            setCatName={setCatName}
            categories={categories}
          />
        </div>
      </div>
      <div className="flex pt-5 items-center">
        <label className="w-44 items-center">Duration</label>
        <input
          className="border ps-2 py-1 w-20 me-4"
          type="number"
          value={duration}
          onChange={(e) => changeDurationFunc(e.target.value)}
        />
        <select
          className="border p-1"
          onChange={(e) => {
            setDurationUnit(e.target.value);
          }}
        >
          <option value="hour" selected={durationUnit === "hour"}>
            Hour(s)
          </option>
          <option value="day" selected={durationUnit === "day"}>
            Day(s)
          </option>
          <option value="week" selected={durationUnit === "week"}>
            Week(s)
          </option>
        </select>
        <p className="ms-5">Set to 0 for the lifetime access.</p>
      </div>
      <div className="flex ">
        <p className="w-44">Course Deadline : </p>
        <p>{deadline !== "none" ? String(new Date(deadline)) : "none"}</p>
      </div>

      <div className="flex items-center">
        <label className="w-44">Level</label>
        <select className="border" onClick={(e) => setLevel(e.target.value)}>
          <option value="all" selected={level === "all"}>
            All levels
          </option>
          <option value="beginner" selected={level === "beginner"}>
            Beginner
          </option>
          <option value="intermediate" selected={level === "intermediate"}>
            Intermediate
          </option>
          <option value="expert" selected={level === "expert"}>
            Expert
          </option>{" "}
        </select>
        <span className="ms-5">Choose a difficulty level.</span>
      </div>
      <div className="flex items-center">
        <label className="w-44">Necessity</label>
        <select
          className="border"
          onClick={(e) => setNecessity(e.target.value)}
        >
          <option value="main" selected={necessity === "main"}>
            Main
          </option>
          <option value="optional" selected={necessity === "optional"}>
            Optional
          </option>
        </select>
      </div>
      <div className="flex items-center">
        <label className="w-44">Prerequisites Courses</label>
        <AddNamesInput
          placeholder={"Search Courses"}
          parentList={prerequisite}
          setParentList={setPrerequisite}
        />
      </div>
      <div className="flex items-center">
        <label className="w-44">Unit</label>
        <input
          className="border ps-2 py-1 w-20 me-4"
          type="number"
          value={unit}
          onChange={(e) => {
            setUnit(e.target.value);
          }}
        />
      </div>
      <div className="flex items-center">
        <label className="w-44">Feature Image</label>
        <div className="w-96">
          <CourseImageSettings />
        </div>
      </div>
    </div>
  );
};

export default CrouseGeneralSettings;
