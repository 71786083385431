import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getUsersByIds } from "../../../redux/usersSlice";

import {
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {} from "@mui/material";
import { getCoursesByIds } from "../../../redux/coursesSlice";

import EachGradeToStudentRow from "./EachGradeToStudentRow";
import UserReportInfo from "./UserReportInfo";

const GradeToStudent = () => {
  // const [courseName, setCourseName] = useState("");
  const currentUser = useSelector((state) => state.user);
  const role = currentUser.profile.role;
  const instructorCoursesId = currentUser.assignedCourses
    ? Object.keys(currentUser.assignedCourses)
    : [];

  const instructorCourses = useSelector((state) =>
    getCoursesByIds(state, instructorCoursesId)
  );

  const instructorStudentId = [];
  instructorCourses?.forEach((course) => {
    const courseStudents = course?.assignedStudents
      ? Object.keys(course.assignedStudents)
      : [];

    courseStudents.forEach((student) => {
      !instructorStudentId.includes(student) &&
        instructorStudentId.push(student);
    });
    // instructorCourses.includes();
  });

  const instructorUsersUnfiltered = useSelector((state) =>
    getUsersByIds(state, instructorStudentId)
  );
  const instructorUsers = instructorUsersUnfiltered.filter(
    (user) => user !== undefined
  );

  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(
    role === "student" ? currentUser : ""
  );
  const users = useSelector(getAllUsers);

  const [assignedCourses, setAssignedCourses] = useState(
    role === "student" && currentUser.assignedCourses
      ? Object.keys(currentUser.assignedCourses)
      : []
  );

  const courses = useSelector((state) =>
    getCoursesByIds(state, assignedCourses)
  );
  console.log(courses);
  const changeUser = (e) => {
    const user = users.find((user) => user.profile.uid === e.target.value);
    setSelectedUser(user);
    if (user.assignedCourses) {
      // if (role === "instructor") {
      //   const coursesToShow = [];
      //   Object.keys(currentUser.assignedCourses).forEach((course) => {
      //     Object.keys(user.assignedCourses).includes(course) &&
      //       coursesToShow.push(course);
      //   });
      //   setAssignedCourses(coursesToShow);
      // } else if (role === "admin") {
      //   setAssignedCourses(Object.keys(user.assignedCourses));
      // }
      console.log(user.assignedCourses);
      setAssignedCourses(Object.keys(user.assignedCourses));
    } else {
      setAssignedCourses([]);
    }
  };

  return (
    <div className=" m-auto mt-20">
      {(role === "admin" || role === "instructor") && (
        <div className="w-8/12 m-auto">
          <FormControl fullWidth>
            <InputLabel id="user-select-label">Select User</InputLabel>
            <Select
              labelId="user-select-label"
              onChange={changeUser}
              label="Select User"
              className="mb-10"
            >
              {role === "admin" &&
                users?.map((user, index) => (
                  <MenuItem key={index} value={user.profile?.uid}>
                    {user.profile?.groupCode && user.profile?.groupCode}
                    ---
                    {user.profile?.email} ---{" "}
                    {user.profile?.studentNumber && user.profile?.studentNumber}{" "}
                  </MenuItem>
                ))}
              {role === "instructor" &&
                instructorUsers?.map((user, index) => (
                  <MenuItem key={index} value={user?.profile?.uid}>
                    {user?.profile?.groupCode && user?.profile?.groupCode}
                    ---
                    {user?.profile?.email} ---{" "}
                    {user?.profile?.studentNumber &&
                      user?.profile?.studentNumber}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}

      <Container>
        {selectedUser && <UserReportInfo item={selectedUser} />}
      </Container>

      <Container>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Instructor</TableCell>
                <TableCell>Publish Date</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Grade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course) => (
                <EachGradeToStudentRow
                  course={course}
                  selectedUser={selectedUser}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default GradeToStudent;
