import { useDispatch, useSelector } from "react-redux";

import { ref, remove } from "firebase/database";
import { db } from "../../../firebase.config";
import {
  addCourseCategory,
  getCourseById,
  removeCourseCategory,
} from "../../../redux/coursesSlice";
import { useParams } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const Category = ({ category, index }) => {
  const len = category.path?.split("/").length;
  // const { courseId } = useParams();
  // const currentCourse = useSelector((state) => getCourseById(state, courseId));
  // const categories = currentCourse?.categoriesName;
  // const catChecked = categories?.some((cat) => cat === category.name);

  // const dispatch = useDispatch();

  // useEffect(() => {

  // }, [catChecked, catSelected, category.name, courseId, dispatch]);

  // const changeCat = (e) => {
  //   if (e.target.checked) {
  //     dispatch(addCourseCategory({ id: courseId, category: category.name }));
  //   } else {
  //     dispatch(removeCourseCategory({ id: courseId, category: category.name }));
  //   }
  // };

  const removeCat = () => {
    if (!category.path) return;
    const docRef = ref(db, "/categories" + category.path);

    if (category?.subCategories) {
      if (
        window.confirm(
          "all sub categories will be deleted, are you want to proceed?"
        )
      ) {
        remove(docRef)
          .then(() => {
            console.log("Path removed successfully!");
          })
          .catch((error) => {
            console.error("Error removing path: ", error);
          });
      }
    } else if (window.confirm("are you sure to remove this category ?")) {
      remove(docRef)
        .then(() => {
          console.log("Path removed successfully!");
        })
        .catch((error) => {
          console.error("Error removing path: ", error);
        });
    }
  };
  // console.log(index);
  // className={`bg-slate-${index % 2 ? "100" : "200"}`}

  return (
    <div
      className={`flex justify-between bg-slate-${
        index % 2 ? "100" : "200"
      } py-2`}
      style={{ paddingLeft: len * 10 + "px" }}
    >
      <p>{category.name}</p>
      <button onClick={removeCat} className="text-red-500 justify-end">
        <DeleteForeverIcon color="bg-cu-red" />
      </button>
    </div>
  );
};
export default Category;

{
  /* {category?.subCategories &&
        Object.values(category?.subCategories)?.map((subCategory) => (
          <Category category={subCategory} level={level + 1} />
        ))} */
}
