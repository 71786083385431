import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserById } from "../../../../redux/usersSlice";
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getAllGroups } from "../../../../redux/groupsSlice";
import LoadingButton from "../../../../shared/comp/LoadingButton";
import GroupCodeSection from "../../../shared/sharedComp/GroupCodeSection";

const CreateEmail = () => {
  const location = useLocation();
  const groups = useSelector(getAllGroups);
  const { uid } = useParams();
  const newUser = useSelector((state) => getUserById(state, uid));
  const currentUser = useSelector((state) => state.user.currentUser);
  const profile = newUser?.profile;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState(profile?.name);
  const [family, setFamily] = useState(profile?.family);
  const [email, setEmail] = useState(profile?.email);
  const [inputEmail, setInputEmail] = useState(
    profile?.email.replace("@cyrusuniversity.org", "")
  );

  const [role, setRole] = useState(profile?.role || "student");
  const [groupCode, setGroupCode] = useState(profile?.groupCode || null);
  //http://127.0.0.1:5001/cyrus-university-website/us-central1/createUser
  // Unhandled error cleaning up build images.
  // https://createuser-mslz25fksq-uc.a.run.app
  // https://us-central1-cyrus-university-website.cloudfunctions.net/createUser
  // https://us-central1-cyrus-university-website.cloudfunctions.net/createUser
  // https://us-central1-cyrus-university-website.cloudfunctions.net/createUser
  //http://127.0.0.1:5001/cyrus-university-website/us-central1/createUser
  //firebase emulators:start
  const addMember = async () => {
    if (!groupCode || !email || !family || !role || !name) {
      toast.error("please specify all field and try again");
      return;
    }
    try {
      setLoading(true);
      const idToken = await currentUser.getIdToken();
      const response = await axios.post(
        "https://us-central1-cyrus-university-website.cloudfunctions.net/createUser",
        {
          email,
          family,
          role,
          name,
          groupCode,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success(`user created successfully`);
      setLoading(false);
      navigate(
        `/adminpanel/addnewmember/${response.data.userRecord.uid}/profilephoto`
      );
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const editEmail = async () => {
    if (!email || !family || !role || !name || !groupCode) {
      toast.error("please specify all field and try again");
      return;
    }
    const oldGroupCode = profile.groupCode;
    try {
      setLoading(true);
      const idToken = await currentUser.getIdToken();
      const response = await axios.post(
        "https://us-central1-cyrus-university-website.cloudfunctions.net/editUserEmail",
        {
          email,
          family,
          role,
          uid,
          name,
          groupCode,
          oldGroupCode,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type": "application/json",
          },
        }
      );
      // if (!response.ok) {

      console.log(response.data);
      // throw new Error(errorData.message || "Something went wrong");
      // }
      if (groupCode) setLoading(false);
      toast.success("data successfully changed");
      navigate("./profilephoto");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div className="flex flex-col p-10 pt-2 gap-y-10 ">
        <div className="flex pt-5">
          <label className="w-44">Firstname</label>
          <input
            className="border ps-2 py-1 w-96"
            type="text"
            placeholder="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="flex">
          <label className="w-44">Family</label>
          <input
            className="border ps-2 py-1 w-96"
            type="text"
            placeholder="family"
            onChange={(e) => setFamily(e.target.value)}
            value={family}
          />
        </div>
        <div className="flex">
          <label className="w-44">Email</label>
          <div className="w-96 border">
            <TextField
              label="Email"
              // disabled={location.pathname.includes("editmember")}
              variant="outlined"
              value={inputEmail}
              onChange={(e) => {
                setInputEmail(e.target.value);
                setEmail(e.target.value + "@cyrusuniversity.org");
              }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    @cyrusuniversity.org
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="flex">
          <label className="w-44">Role</label>
          {/* <div className="w-96 flex"> */}
          {/* <span className='text-slate-500'>Role : </span> */}
          <div className="w-96">
            <Box className={"w-full"} sx={{ margin: "auto" }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="select-role">Role</InputLabel>
                <Select
                  labelId="select-role"
                  label="Role"
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                >
                  <MenuItem value="instructor" selected={role === "instructor"}>
                    Instructor
                  </MenuItem>
                  <MenuItem value="student" selected={role === "student"}>
                    Student
                  </MenuItem>
                  <MenuItem value="staff" selected={role === "staff"}>
                    Staff
                  </MenuItem>
                  <MenuItem value="admin" selected={role === "admin"}>
                    Admin
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className="flex">
          <label className="w-44">Group</label>
          <div className="w-96">
            <GroupCodeSection
              groupCode={groupCode}
              setGroupCode={setGroupCode}
              disabled={false}
            />
          </div>
        </div>
        <div className="m-auto w-96">
          {!newUser ? (
            <div onClick={addMember}>
              {" "}
              <LoadingButton
                loading={loading}
                disabled={!email || !name || !family || !groupCode}
                name={"Create User"}
              />
            </div>
          ) : (
            <div onClick={editEmail}>
              {" "}
              <LoadingButton
                loading={loading}
                disabled={!email || !name || !family || !groupCode}
                name={"Edit User"}
              />
            </div>
          )}
        </div>

        {/* </div> */}
      </div>
      {/* <ShowAllUsers /> */}
      {/* </div> */}
    </div>
  );
};

export default CreateEmail;
