// import React, { useState, useRef, useEffect } from "react";
// import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useNavigate } from "react-router-dom";
// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
// import { toast } from "react-toastify";
// import {
//   deleteRealItem,
//   deleteStorageRecursively,
// } from "../../../firebase.config";
// import PreviewIcon from "@mui/icons-material/Preview";
// import { getUsersByIds } from "../../../redux/usersSlice";
// import { useSelector } from "react-redux";
// import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
// import { Avatar, Button, Typography } from "@mui/material";
// import TimeShow from "../../../shared/utils/TimeShow";
// import { getGroupById } from "../../../redux/groupsSlice";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import EditIcon from "@mui/icons-material/Edit";
// const CourseRow = ({ item }) => {
//   const user = useSelector((state) => state.user);
//   const courseId = item.info.courseId;
//   const groupCode = item.info.groupCode;
//   const navigate = useNavigate();
//   const [menuOpen, setMenuOpen] = useState(false);
//   const menuRef = useRef(null);

//   const goToEditCourse = () => {
//     navigate(`/${user.profile.role}panel/editcourse/${courseId}`);
//   };

//   const group = useSelector((state) => getGroupById(state, groupCode));
//   const assignedInstructorsId = item.assignedInstructor
//     ? Object.keys(item.assignedInstructor)
//     : [];
//   const instructorsUnfiltered = useSelector((state) =>
//     getUsersByIds(state, assignedInstructorsId)
//   );
//   const instructors = instructorsUnfiltered.filter((ins) => ins !== undefined);

//   const deleteCourse = async () => {
//     if (!courseId || !groupCode) {
//       toast.error("can not find course ID");
//       return;
//     }
//     if (window.confirm("are you sure to remove course?")) {
//       const realCoursePath = `/lact/${groupCode}/courses/${courseId}`;
//       const storageCoursePath = `/courses/${courseId}`;
//       const usersId = item.assignedStudents
//         ? Object.keys(item.assignedStudents)
//         : [];
//       const usersCourseDeletePromise = usersId?.map((uid) => {
//         if (!uid || !groupCode) {
//           toast.error(`uid isnt exists, an error take place please check db`);
//           throw new Error("id not found");
//         } else {
//           const userPath = `/lact/${groupCode}/users/${uid}/assignedCourses/${courseId}`;
//           return deleteRealItem(userPath);
//         }
//       });
//       const lessonsId = item.assignedLessons
//         ? Object.keys(item.assignedLessons)
//         : [];
//       const lessonsCourseDeletePromise = lessonsId?.map((li) => {
//         if (!li) {
//           toast.error(
//             `lesson ID isnt exists, an error take place please check db`
//           );
//           throw new Error("id not found");
//         } else {
//           const lessonPath = `lact/${groupCode}/lessons/${li}/assignedCourses/${courseId}`;
//           return deleteRealItem(lessonPath);
//         }
//       });
//       try {
//         await Promise.all(lessonsCourseDeletePromise);
//         await Promise.all(usersCourseDeletePromise);
//         await deleteRealItem(realCoursePath);
//         await deleteStorageRecursively(storageCoursePath);
//         toast.success("successfully delete course");
//       } catch (error) {
//         toast.error(error.message);
//         console.log(error);
//       }
//     }
//   };

//   const viewCourse = () => {
//     navigate(
//       `/${user.profile.role}panel/courses/viewcourse/${item.info.courseId}`
//     );
//   };

//   const openMenu = () => {
//     setMenuOpen(true);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         closeMenu();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <tr className="border-b h-12 flex items-center relative text-center">
//       <div className="absolute right-0 top-0 text-blue-600 h-full me-1 flex items-center cursor-pointer">
//         <Button onClick={openMenu}>
//           <MoreHorizIcon className="text-cu-blue" />
//         </Button>
//         {menuOpen && (
//           <div
//             ref={menuRef}
//             className="absolute top-full right-0 bg-white shadow-lg p-1 z-40 w-32 flex flex-col gap-y-2 items-start rounded-xl border"
//           >
//             {/* <div className=""> */}
//             <Button
//               onClick={viewCourse}
//               sx={{
//                 fontSize: "10px",
//                 display: "flex",
//                 justifyContent: "start",
//               }}
//               className="gap-x-2 w-full "
//             >
//               <PreviewIcon className="text-blue-500" sx={{ width: "20px" }} />{" "}
//               <span>Review</span>
//             </Button>
//             {/* </div> */}
//             {/* <div className="border-b-2 border-b-cu-red w-full flex items-start"> */}
//             <Button
//               onClick={goToEditCourse}
//               sx={{
//                 fontSize: "10px",
//                 display: "flex",
//                 justifyContent: "start",
//               }}
//               className="w-full gap-x-2"
//             >
//               <EditIcon sx={{ width: "20px" }} />
//               <span>Edit</span>
//             </Button>
//             {/* </div> */}
//             <Button
//               onClick={deleteCourse}
//               sx={{
//                 fontSize: "10px",
//                 display: "flex",
//                 justifyContent: "start",
//                 color: "#bf0a30",
//               }}
//               className=" w-full gap-x-2"
//             >
//               <DeleteForeverOutlinedIcon sx={{ width: "20px" }} /> Delete
//             </Button>
//           </div>
//         )}
//       </div>
//       <td className="w-1/12">
//         <Avatar
//           className="rounded-lg ms-3 h-full"
//           sx={{ objectFit: "contain" }}
//           src={item?.files?.courseImage?.url}
//           alt={item?.info?.title}
//           variant="rounded"
//         >
//           {!item?.files?.courseImage?.url && (
//             <InsertPhotoIcon sx={{ fontSize: "40px" }} />
//           )}
//         </Avatar>
//       </td>
//       <td className="w-1/6">
//         <p>{item.info?.title}</p>
//       </td>
//       <td className="w-1/6">
//         <p>{item.info?.name || "---"}</p>
//       </td>
//       <td className="flex flex-col gap-y-2 w-1/6">
//         {item?.categoriesName?.length
//           ? item?.categoriesName?.map((catName) => (
//               <span key={catName}>{catName}</span>
//             ))
//           : "---"}
//       </td>
//       <td className="flex flex-col gap-y-2 w-1/6">
//         {instructors.length &&
//           instructors?.map(
//             (ins) => ins?.profile?.name + " " + ins?.profile?.family
//           )}
//       </td>
//       <td className="w-1/12">
//         {item?.info?.createAt && (
//           <TimeShow
//             onlyDate={true}
//             date={new Date(item?.info?.createAt)}
//           />
//         )}
//       </td>
//       <td className="w-1/12">
//         <Avatar
//           className="rounded-full border m-auto"
//           sx={{ bgcolor: `${group.color}`, width: "15px", height: "15px" }}
//         >
//           {/* {group.id} */}
//         </Avatar>
//       </td>
//     </tr>
//   );
// };

// export default CourseRow;

import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteRealItem,
  deleteStorageRecursively,
} from "../../../firebase.config";
import { getUsersByIds } from "../../../redux/usersSlice";
import { useSelector } from "react-redux";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Avatar, Typography } from "@mui/material";
import TimeShow from "../../../shared/utils/TimeShow";
import { getGroupById } from "../../../redux/groupsSlice";
import ActionsMenu from "../sharedComp/ActionsMenu";
import ActionsCourseMenu from "./ActionsCourseMenu";

const CourseRow = ({ item }) => {
  const user = useSelector((state) => state.user);
  const courseId = item.info.courseId;
  const groupCode = item.info.groupCode;
  const navigate = useNavigate();

  const group = useSelector((state) => getGroupById(state, groupCode));
  const assignedInstructorsId = item.assignedInstructor
    ? Object.keys(item.assignedInstructor)
    : [];
  const instructorsUnfiltered = useSelector((state) =>
    getUsersByIds(state, assignedInstructorsId)
  );
  const instructors = instructorsUnfiltered.filter((ins) => ins !== undefined);

  const goToEditCourse = useCallback(() => {
    navigate(`/${user.profile.role}panel/editcourse/${courseId}`);
  }, [navigate, user.profile.role, courseId]);

  const deleteCourse = useCallback(async () => {
    if (!courseId || !groupCode) {
      toast.error("Cannot find course ID");
      return;
    }
    if (window.confirm("Are you sure you want to remove this course?")) {
      try {
        const realCoursePath = `/lact/${groupCode}/courses/${courseId}`;
        const storageCoursePath = `/courses/${courseId}`;
        const usersId = item.assignedStudents
          ? Object.keys(item.assignedStudents)
          : [];
        const lessonsId = item.assignedLessons
          ? Object.keys(item.assignedLessons)
          : [];

        await Promise.all(
          usersId.map((uid) =>
            deleteRealItem(
              `/lact/${groupCode}/users/${uid}/assignedCourses/${courseId}`
            )
          )
        );
        await Promise.all(
          lessonsId.map((li) =>
            deleteRealItem(
              `lact/${groupCode}/lessons/${li}/assignedCourses/${courseId}`
            )
          )
        );
        await deleteRealItem(realCoursePath);
        await deleteStorageRecursively(storageCoursePath);
        toast.success("Successfully deleted course");
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    }
  }, [courseId, groupCode, item.assignedStudents, item.assignedLessons]);

  const viewCourse = useCallback(() => {
    navigate(
      `/${user.profile.role}panel/courses/viewcourse/${item.info.courseId}`
    );
  }, [navigate, user.profile.role, item.info.courseId]);

  return (
    <tr className="border-b h-12 flex items-center relative text-center">
      {/* Use the CourseActionsMenu component */}
      <ActionsCourseMenu
        onView={viewCourse}
        onEdit={goToEditCourse}
        onDelete={deleteCourse}
        course={item}
      />

      <td className="w-1/12">
        <Avatar
          className="rounded-lg ms-3 h-full"
          sx={{ objectFit: "contain" }}
          src={item?.files?.courseImage?.url}
          alt={item?.info?.title}
          variant="rounded"
        >
          {!item?.files?.courseImage?.url && (
            <InsertPhotoIcon sx={{ fontSize: "40px" }} />
          )}
        </Avatar>
      </td>
      <td className="w-1/6">
        <p>{item.info?.title}</p>
      </td>
      <td className="w-1/6">
        <p>{item.info?.name || "---"}</p>
      </td>
      <td className="flex flex-col gap-y-2 w-1/6">
        {item?.categoriesName?.length
          ? item?.categoriesName?.map((catName) => (
              <span key={catName}>{catName}</span>
            ))
          : "---"}
      </td>
      <td className="flex flex-col gap-y-2 w-1/6">
        {instructors.length &&
          instructors.map(
            (ins) => ins?.profile?.name + " " + ins?.profile?.family
          )}
      </td>
      <td className="w-1/12">
        {item?.info?.createAt && (
          <TimeShow onlyDate={true} date={new Date(item?.info?.createAt)} />
        )}
      </td>
      <td className="w-1/12">
        <Avatar
          className="rounded-full border m-auto"
          sx={{ bgcolor: `${group.color}`, width: "15px", height: "15px" }}
        />
      </td>
    </tr>
  );
};

export default CourseRow;
